import { ProcedureCard } from "./ProcedureCard";
import { ProcedureSummary } from "../types/ProcedureSummary";

type SearchResultsProps = {
  results: ProcedureSummary[];
};

export function SearchResults(props: SearchResultsProps) {
  const { results } = props;

  const standards = [...new Set(results.map((item) => item.standard))];
  const recordTypes = (standard: string) => [
    ...new Set(results.filter((x) => x.standard === standard).map((item) => item.recordType)),
  ];
  const procedures = (standard: string, recordType: string) =>
    results.filter((x) => x.standard === standard && x.recordType === recordType);

  return (
    <>
      {standards.map((standard, index) => (
        <div className="card expanded" key={index}>
          <div className="card-header">{standard}</div>
          <div className="card-body pb-0">
            {recordTypes(standard).map((recordType, index) => (
              <div className="card-text detail" key={index}>
                <ul className="list-group list-group-flush mt-0">
                  <li className="list-group-item active">{recordType}</li>
                  {procedures(standard, recordType).map((procedure, index) => (
                    <li className="list-group-item" key={index}>
                      <ProcedureCard procedure={procedure} />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}
