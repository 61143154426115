import { Dispatch } from "react";
import { RbiAction } from "../reducer";
import { WelderState } from "../types";

type WeldSelectionFiltersProps = {
  state: WelderState;
  updateState: Dispatch<RbiAction>;
};

export function WeldSelectionFilters({ state, updateState }: WeldSelectionFiltersProps) {
  function handleFilterChanged(e: React.FormEvent<HTMLInputElement>) {
    updateState({
      type: "Filters",
      payload: { ...state.filters, [e.currentTarget.id]: e.currentTarget.checked },
    });
  }

  return (
    <div className="mt-2">
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="onlyMyWelds"
          checked={state.filters.onlyMyWelds}
          onChange={handleFilterChanged}
        />
        <label className="form-check-label" htmlFor="onlyMyWelds">
          Only welds assigned to me
        </label>
      </div>

      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="onlyWeldsNotMade"
          checked={state.filters.onlyWeldsNotMade}
          onChange={handleFilterChanged}
        />
        <label className="form-check-label" htmlFor="onlyWeldsNotMade">
          Only welds not already made
        </label>
      </div>

      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="onlyWeldsFromSequence"
          checked={state.filters.onlyWeldsFromSequence}
          onChange={handleFilterChanged}
        />
        <label className="form-check-label" htmlFor="onlyWeldsFromSequence">
          Only welds from current sequence
        </label>
      </div>

      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="onlyRepairWelds"
          checked={state.filters.onlyRepairWelds}
          onChange={handleFilterChanged}
        />
        <label className="form-check-label" htmlFor="onlyRepairWelds">
          Only repair welds
        </label>
      </div>
    </div>
  );
}
