import { api } from "@api";
import { Project, SubProject } from "@entities";

export function getProjectList() {
  return api.get<Project[]>("projects");
}

export function getSubProjectsByProject(projectId?: string) {
  return api.get<SubProject[]>(`subprojects?projectId=${projectId}`);
}
