import { api } from "@api";

export function getCoordinatorMenu(menu: string) {
  return api.get<string[]>(`menus/coordinator/${menu}`);
}

export function getWpsList(process: string) {
  const params = new URLSearchParams({ process });
  return api.get<string[]>(`menus/wpslist?${params.toString()}`);
}

export function getFillerClassifications(process: string, wps: string) {
  const params = new URLSearchParams({ process, wps });
  return api.get<string[]>(`menus/fillers?${params.toString()}`);
}
