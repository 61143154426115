import { DatasheetSelection } from "../common/DatasheetSelection";
import { defaultState } from "./types";
import { MainInterface } from "./components/MainInterface";
import { reducer, WelderContext } from "./reducer";
import { useReducer } from "react";
import { useSearchParams } from "react-router-dom";
import { WeldSelection } from "./components/WeldSelection";

export function WelderRole() {
  const [state, updateState] = useReducer(reducer, defaultState);
  const [searchParams] = useSearchParams();

  const currentPage = Number(searchParams.get("page")) || 1;

  return (
    <WelderContext.Provider value={{ state, updateState }}>
      {currentPage === 1 && <DatasheetSelection updateState={updateState} {...state} />}
      {currentPage === 2 && <WeldSelection />}
      {currentPage === 3 && <MainInterface />}
    </WelderContext.Provider>
  );
}
