import z from "zod";

export const ChangePasswordSchema = z
  .object({
    oldPassword: z.string().min(1, "Enter your current password"),
    newPassword: z.string().min(1, "Enter your new password"),
    newPasswordRepeat: z.string().min(1, "Repeat your new password"),
  })
  .refine((data) => data.newPassword === data.newPasswordRepeat, {
    message: "Passwords do not match",
    path: ["newPasswordRepeat"],
  });

export type PasswordChangeRequest = z.infer<typeof ChangePasswordSchema>;
