import * as api from "api/datasheets";
import { useMutation, useQuery } from "react-query";

export function useGetDatasheetsBySubProject(subProjectId?: string) {
  const queryKey = ["datasheets", subProjectId];
  return useQuery(queryKey, () => api.getDatasheetsBySubProject(subProjectId), {
    enabled: Boolean(subProjectId),
  });
}

export function useGetDatasheet(datasheetId?: string) {
  const queryKey = ["datasheet", datasheetId];
  return useQuery(queryKey, () => api.getDatasheet(datasheetId), {
    enabled: Boolean(datasheetId),
  });
}

export function useScanDatasheet() {
  return useMutation((datasheetId: string) => api.scanDatasheet(datasheetId));
}
