import { Token } from "@entities";

export class IndexState {
  isAuthenticating: boolean = false;
  isAuthenticationError: boolean = false;
  isAuthenticationFailure: boolean = false;
  isUserIdRequired: boolean = false;
  isResetAccepted: boolean = false;
  isReset: boolean = false;
  isResetComplete: boolean = false;

  isPasswordRequestMismatch: boolean = false;
  isPasswordRequestError: boolean = false;
  isPasswordAllowed: boolean = false;
  isPasswordRequest: boolean = false;
  resetToken: Token | null = null;
}
