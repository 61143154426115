import { InputHTMLAttributes } from "react";
import { useCoordinatorMenu } from "@queries/menu.queries";

export function ProcessLookup(props: InputHTMLAttributes<HTMLInputElement>) {
  const lookup = useCoordinatorMenu("WeldingProcess(US)");

  return (
    <>
      <input type="text" className="form-select" list="processes" {...props} />
      <datalist id="processes">
        {lookup.data?.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
    </>
  );
}
