import { Navigate, Outlet } from "react-router-dom";
import { ServiceClient } from "@utils";

export function LoginCheck() {
  if (ServiceClient.isAuthenticated()) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" replace />;
  }
}
