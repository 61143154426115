export interface RecordAttachment {
  recordId: string;
  attachmentId: string;
  attachmentFormat: AttachmentFormat;
  fileName: string;
  filePath: string;
}

export enum AttachmentFormat {
  File = 0,
  URL = 1,
}
