import * as api from "api/tokens";
import { LoginCredentials, Token } from "@entities";
import { PasswordChangeRequest } from "schemas/password.schema";
import { ServiceClient } from "@utils";
import { useMutation } from "react-query";

export function useChangePassword() {
  return useMutation((request: PasswordChangeRequest) => api.changePassword(request));
}

export function useLogin(withBarcode?: boolean) {
  return useMutation((credentials: LoginCredentials) => api.login(credentials), {
    onSuccess: (token: Token) => {
      ServiceClient.setToken(token, false);

      if (withBarcode) {
        window.location.href = "/rbi";
      } else {
        window.location.href = "/certificates";
      }
    },
  });
}
