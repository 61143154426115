import ModalDialog from "@components/ModalDialog";
import { DateTime } from "@utils";
import { IsoDrawingWeld } from "@entities";

type WeldInformationProps = {
  caption: string;
  selectedWeld: IsoDrawingWeld;
  onClose: (result: boolean) => void;
};

export function WeldInformation(props: WeldInformationProps) {
  const { caption, onClose, selectedWeld } = props;

  const fields = [
    { label: "Sub-project", value: selectedWeld.subProjectName },
    { label: "Datasheet", value: selectedWeld.datasheetName },
    { label: "Weld type", value: selectedWeld.weldJointType },
    { label: "Inspection class", value: selectedWeld.weldInspectionClass },
    { label: "Processes", value: selectedWeld.processes },
    { label: "Made date", value: DateTime.getLocalDate(selectedWeld.weldMadeDate) || "" },
  ];

  return (
    <ModalDialog caption={caption} onClose={onClose}>
      {fields
        .filter((x) => x.value)
        .map((field, index) => (
          <div key={index} className="row p-1">
            <div className="col">{field.label}</div>
            <div className="col">
              <input className="form-control" value={field.value} readOnly />
            </div>
          </div>
        ))}
    </ModalDialog>
  );
}
