import { api } from "@api";
import { CertificateSummary } from "./types/CertificateSummary";
import { useQuery } from "react-query";
import { Welder } from "@entities";

export function useGetWelders(skip: number, take: number, filters: string) {
  return useQuery(["welders", skip, filters], () =>
    api.getPaginated<Welder[]>(`welders?skip=${skip}&take=${take}&${filters}`)
  );
}

export function useGetWelderCard(welderId: string, enabled: boolean) {
  const queryKey = ["certificates", welderId];
  return useQuery(queryKey, () => api.get<CertificateSummary[]>(`certificates?welderId=${welderId}`), { enabled });
}
