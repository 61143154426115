import { api } from "@api";
import { CoordinatorUser, UserSignature } from "@entities";

export function getUserSignatures() {
  return api.get<UserSignature[]>("user/signatures");
}

export function saveUserSignature(signature: UserSignature) {
  return api.post("user/signatures", signature);
}

export function getCoordinatorUser() {
  return api.get<CoordinatorUser>("user/coordinator");
}
