import { GenericDialog } from "@components/dialogs";
import { useState } from "react";

export const ErrorDialog = ({ message }: { message: string }) => {
  return (
    <GenericDialog caption="Error" buttons="OK">
      {message}
    </GenericDialog>
  );
};

export const SaveChangesDialog = (props: { onAccept: () => void }) => {
  return (
    <GenericDialog caption="Save Changes" buttons="OKCancel" onAccept={props.onAccept}>
      Are you sure you want to save changes and finish?
    </GenericDialog>
  );
};

export const CommentsDialog = (props: { comment: string; onAccept: (comment: string) => void }) => {
  const [comment, setComment] = useState<string>(props.comment);

  return (
    <GenericDialog caption="Comments" buttons="OKCancel" onAccept={() => props.onAccept(comment)}>
      <textarea
        className="form-control"
        rows={5}
        cols={60}
        value={comment}
        onChange={(e) => setComment(e.currentTarget.value)}
        autoFocus
      />
    </GenericDialog>
  );
};

export const ScanDatasheetDialog = (props: { onScan: (datasheetId: string) => void }) => {
  const [QRCode, setQRCode] = useState<string>("");

  return (
    <GenericDialog caption="Scan QR Code" buttons="OKCancel" onAccept={() => props.onScan(QRCode)}>
      <input
        type="text"
        className="form-control"
        value={QRCode}
        onChange={(e) => setQRCode(e.currentTarget.value)}
        autoFocus
      />
    </GenericDialog>
  );
};
