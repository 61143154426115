import { Datasheet, Project, SubProject, Welder } from "@entities";

export type Weld = {
  weldId: string;
  weldNumber: string;
  datasheet: string;
  jointType: string;
  fillerType: string;
  fillerBatch: string;
  component1: string;
  component2: string;
  itemNumber: string;
  wpsNumber: string;
  uniqueIdA: string;
  uniqueIdB: string;
  meltNumberA: string;
  meltNumberB: string;
  region: string;
  status: string;
  welders: Welder[];
};

export type ParalloyState = {
  project?: Project;
  subProject?: SubProject;
  datasheet?: Datasheet;
  welds: Weld[];
};

export const defaultState: ParalloyState = {
  welds: [],
};

export type StateType = "Project" | "SubProject" | "Datasheet" | "Welds";

export type CheckoutWeldsResponse = {
  success: boolean;
  completed: Weld[];
};
