import * as React from 'react';

export class Page {
    static getInputValue(e:React.FormEvent<HTMLFormElement>, name:string) {
        return (e.currentTarget.elements.namedItem(name) as HTMLInputElement).value;
    }

    static getInputChecked(e:React.FormEvent<HTMLFormElement>, name:string) {
        return (e.currentTarget.elements.namedItem(name) as HTMLInputElement).checked;
    }

    static redirect(location:string | null = null) {
        if (location) {
            window.location.href = location;
        } else {
            location = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
            window.location.href = location;
        }
    }

    static getUrlVars() {
        const vars: Record<string, string> = {};
        const hashes = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");
        for (const hsh of hashes) {
            const hash = hsh.split("=");
            vars[hash[0]] = hash[1];
        }

        return vars;
    }
}