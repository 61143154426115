import { api } from "@api";
import { Datasheet } from "@entities";
import { ScanDatasheetResponse } from "@entities/ScanDatasheetResponse";

export function getDatasheetsBySubProject(subProjectId?: string) {
  return api.get<Datasheet[]>(`datasheets?subProjectId=${subProjectId}`);
}

export function getDatasheet(datasheetId?: string) {
  return api.get<Datasheet>(`datasheets/${datasheetId}`);
}

export function scanDatasheet(datasheetId: string) {
  return api.post<ScanDatasheetResponse>(`datasheets/scan?barcode=${datasheetId}`);
}
