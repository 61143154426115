import { CheckoutWeldsResponse, ParalloyState, Weld } from "../types";
import { Dispatch, useState } from "react";
import { ErrorDialog } from "../../../common/Dialogs";
import { PageButton, PageControls } from "../../../common/PageControls";
import { Pagination } from "@components";
import { RbiAction } from "../reducer";
import { ServiceClient } from "@utils";
import { useConfiguration } from "@queries/configuration.queries";
import { useModal } from "hooks";
import { useWeldCheckout, useWeldList } from "../queries";

const WELDS_PER_PAGE = 20;

type WeldSelectionProps = {
  state: ParalloyState;
  updateState: Dispatch<RbiAction>;
};

export function WeldSelection({ state, updateState }: WeldSelectionProps) {
  const [skip, setSkip] = useState(0);

  const welds = useWeldList(state, skip, WELDS_PER_PAGE);
  const checkout = useWeldCheckout();
  const config = useConfiguration();
  const modal = useModal();

  const allowMultiple = config.data?.rbiWelderMultipleWelds;

  function handleWeldSelected(e: React.FormEvent<HTMLInputElement>, weld: Weld) {
    const limit = config.data?.rbiWeldLimit ?? 10;
    if (e.currentTarget.checked && state.welds.length >= limit) {
      modal.show(<ErrorDialog message={`You can only check out ${limit} welds at a time.`} />);
      return;
    }

    if (e.currentTarget.checked) {
      updateState({
        type: "Welds",
        payload: allowMultiple ? [...state.welds, weld] : [weld],
      });
    } else {
      updateState({
        type: "Welds",
        payload: state.welds.filter((x) => x.weldId !== weld.weldId),
      });
    }
  }

  function checkoutWelds() {
    checkout.mutate(state.welds, {
      onSuccess: onCheckoutResponse,
    });
  }

  function onCheckoutResponse(response: CheckoutWeldsResponse) {
    if (response.success) {
      ServiceClient.logout();
    } else {
      modal.show(<ErrorDialog message="Some welds are already completed and have been unselected." />);
      updateState({
        type: "Welds",
        payload: state.welds.filter((x) => !response.completed.some((y) => x.weldId === y.weldId)),
      });
    }
  }

  if (welds.isLoading) {
    return <p>Loading welds...</p>;
  }

  return (
    <>
      <div className="text-secondary mb-4">
        {state.project?.name} / {state.subProject?.name} / {state.datasheet?.datasheetName}
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-hover text-center text-nowrap">
          <thead>
            <tr>
              <th>Weld ID</th>
              <th>Joint Type</th>
              <th>Comp. A</th>
              <th>Unique ID A</th>
              <th>Comp. B</th>
              <th>Unique ID B</th>
              <th>WPS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {welds.data?.results.map((weld, index) => (
              <tr key={index}>
                <th>{weld.weldNumber}</th>
                <td>{weld.jointType}</td>
                <td>{weld.component1}</td>
                <td>{weld.uniqueIdA}</td>
                <td>{weld.component2}</td>
                <td>{weld.uniqueIdB}</td>
                <td>{weld.wpsNumber}</td>
                <td>
                  <input
                    type={allowMultiple ? "checkbox" : "radio"}
                    className="form-check-input"
                    checked={state.welds.some((e) => e.weldId === weld.weldId)}
                    onChange={(e) => handleWeldSelected(e, weld)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {welds.data && welds.data.total > WELDS_PER_PAGE && (
        <Pagination
          current={welds.data.page}
          total={welds.data.total}
          count={welds.data.results.length}
          pageSize={WELDS_PER_PAGE}
          onPageChanged={setSkip}
        />
      )}

      <PageControls showBack allowBack={!checkout.isLoading}>
        <PageButton
          onClick={checkoutWelds}
          loading={checkout.isLoading}
          disabled={checkout.isLoading || state.welds.length === 0}
        >
          Checkout
        </PageButton>
      </PageControls>
    </>
  );
}
