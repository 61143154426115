import { createContext, Dispatch } from "react";
import { Datasheet, Project, SubProject } from "@entities";
import { Process, StateType, Weld, WelderState, WeldListFilters } from "./types";

export interface IWelderContext {
  state: WelderState;
  updateState: Dispatch<RbiAction>;
}

export const WelderContext = createContext<IWelderContext>({} as IWelderContext);

export type RbiAction = {
  type: StateType;
  payload?: Project | SubProject | Datasheet | Weld[] | Process[] | WeldListFilters;
};

export const reducer = (state: WelderState, action: RbiAction): WelderState => {
  switch (action.type) {
    case "Project":
      return { ...state, project: action.payload as Project, subProject: undefined, datasheet: undefined };
    case "SubProject":
      return { ...state, subProject: action.payload as SubProject, datasheet: undefined };
    case "Datasheet":
      return { ...state, datasheet: action.payload as Datasheet, welds: [], processes: [] };
    case "Welds":
      return { ...state, welds: action.payload as Weld[], processes: [] };
    case "Processes":
      return { ...state, processes: action.payload as Process[] };
    case "Filters":
      return { ...state, filters: action.payload as WeldListFilters, welds: [], processes: [] };
    default:
      return state;
  }
};
