import { ModalDialog } from "@components/ModalDialog";
import { RecordSignature } from "@entities";
import { useGetUserSignatures } from "@queries/user.queries";
import { useRevalidate } from "@queries/certificate.queries";
import { useSignSignature } from "@queries/record.queries";
import { useState } from "react";

export type SignatureEntryProps = {
  type?: "Record" | "Revalidation";
  signature: RecordSignature;
};

export function SignatureEntry(props: SignatureEntryProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedSignature, setSelectedSignature] = useState(0);

  const signSignature = useSignSignature(props.signature);
  const revalidate = useRevalidate(props.signature);
  const userSignatures = useGetUserSignatures();

  function onModalClosed(result: boolean) {
    setShowConfirmation(false);
    if (result) {
      let userSignature;
      if (userSignatures.isSuccess && userSignatures.data?.length > selectedSignature) {
        userSignature = userSignatures.data[selectedSignature];
      }

      if (props.type === "Revalidation") {
        revalidate.mutate(userSignature);
      } else {
        signSignature.mutate(userSignature);
      }
    }
  }

  return (
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id="signature"
        checked={props.signature.signed}
        disabled={props.signature.signed}
        onChange={() => setShowConfirmation(true)}
      />
      <label htmlFor="signature" className="form-check-label fw-normal">
        {props.signature.title || "Signature"}
      </label>

      {showConfirmation && (
        <ModalDialog
          caption={props.type === "Revalidation" ? "Revalidate certificate?" : "Sign Record?"}
          onClose={onModalClosed}
        >
          <>
            {props.type === "Record" && <p>Are you sure you want to sign signature {props.signature.title}?</p>}
            {props.type === "Revalidation" && <p>Are you sure you want to revalidate this certificate?</p>}

            {userSignatures.isSuccess &&
              userSignatures.data.length > 1 &&
              userSignatures.data.map((signature, index) => (
                <div key={index} className="mb-2">
                  <input
                    type="radio"
                    name="signature"
                    checked={selectedSignature === index}
                    onChange={() => setSelectedSignature(index)}
                  ></input>
                  <img src={signature.signature} width="150" height="50" className="user-signature ms-3" />
                </div>
              ))}
          </>
        </ModalDialog>
      )}
    </div>
  );
}
