import { api } from "@api";
import { Datasheet } from "@entities";
import { Page } from "@utils";
import { Process, Weld, WeldListFilters, WelderState } from "./types";
import { useMutation, useQuery } from "react-query";
import { Dispatch } from "react";
import { RbiAction } from "./reducer";

export function useGetWeldList(filters: WeldListFilters, datasheet?: Datasheet) {
  return useQuery(["hitachi/rbi/welder/welds", datasheet, filters], () => fetchWeldList(filters, datasheet), {
    enabled: Boolean(datasheet),
  });
}

export function useGetProcesses(state: WelderState, updateState: Dispatch<RbiAction>) {
  const route = "hitachi/rbi/welder/processes";
  const weldIds = state.welds.map((x) => x.weldId);

  return useQuery([route, weldIds], () => api.post<Process[]>(route, weldIds), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      updateState({ type: "Processes", payload: data });
    },
  });
}

export function useSave() {
  return useMutation((processes: Process[]) => api.post("hitachi/rbi/welder", processes), {
    onSuccess: () => {
      Page.redirect("rbi/hitachi/welder");
    },
  });
}

function fetchWeldList(filters: WeldListFilters, datasheet?: Datasheet) {
  const params = new URLSearchParams({
    datasheetId: datasheet?.datasheetId?.toString() ?? "",
    onlyMyWelds: filters.onlyMyWelds.toString(),
    onlyWeldsNotMade: filters.onlyWeldsNotMade.toString(),
    onlyWeldsFromCurrentSequence: filters.onlyWeldsFromSequence.toString(),
    onlyRepairWelds: filters.onlyRepairWelds.toString(),
  });

  return api.get<Weld[]>(`hitachi/rbi/welder/welds?${params.toString()}`);
}
