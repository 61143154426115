import { FillerMatrixLookup, ProcessLookup, WPSLookup } from "../../../common/lookup";
import { PageButton, PageControls } from "../../../common/PageControls";
import { Process } from "../types";
import { SaveChangesDialog } from "../../../common/Dialogs";
import { String } from "@utils";
import { useContext } from "react";
import { useGetProcesses, useSave } from "../queries";
import { useModal } from "@hooks";
import { WelderContext } from "../reducer";

export const MainInterface = () => {
  const { state, updateState } = useContext(WelderContext);
  const { isLoading } = useGetProcesses(state, updateState);

  const save = useSave();
  const modal = useModal();

  if (isLoading) {
    return <p>Loading welds...</p>;
  }

  function isGroupRow(row: Process) {
    const index = state.processes.indexOf(row);
    return index === 0 || row.weldId !== state.processes[index - 1].weldId;
  }

  function handleFieldChanged(row: Process, field: string, value: string | boolean) {
    const processes = [...state.processes];
    const index = processes.indexOf(row);
    processes[index] = { ...processes[index], [field]: value };

    updateState({
      type: "Processes",
      payload: processes,
    });
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover text-center text-nowrap">
          <thead>
            <tr>
              <th>Weld No.</th>
              <th>Process</th>
              <th>HIT.WPS</th>
              <th>Filler</th>
              <th>Lot No.</th>
              <th>NAY Weld Asset No.</th>
              <th>Deposited</th>
              <th>Made</th>
              <th>Visual</th>
              <th>Unfinished</th>
              <th>TIG Wash</th>
              <th>Grind</th>
            </tr>
          </thead>
          <tbody>
            {state.processes.map((row, index) => (
              <tr key={index}>
                <th>{isGroupRow(row) && row.weldNumber}</th>
                <td>
                  <ProcessLookup
                    id="process"
                    value={row.process}
                    onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.value)}
                  />
                </td>
                <td>
                  <WPSLookup
                    id="wpsNumber"
                    process={row.process}
                    value={row.wpsNumber}
                    onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process)}
                  />
                </td>
                <td>
                  <FillerMatrixLookup
                    id="filler"
                    process={row.process}
                    wpsNumber={row.wpsNumber}
                    value={row.filler}
                    onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    id="lotNumber"
                    className="form-control"
                    value={row.lotNumber}
                    onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    id="assetNumber"
                    className="form-control"
                    value={row.assetNumber}
                    onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process)}
                  />
                </td>
                <td>
                  <div className="form-check form-switch d-flex justify-content-center">
                    <input
                      type="checkbox"
                      id="deposited"
                      className="form-check-input"
                      checked={row.deposited}
                      onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.checked)}
                      disabled={String.isNullOrWhiteSpace(row.wpsNumber) || String.isNullOrWhiteSpace(row.filler)}
                    />
                  </div>
                </td>
                <td>
                  {isGroupRow(row) && (
                    <div className="form-check form-switch d-flex justify-content-center">
                      <input
                        type="checkbox"
                        id="made"
                        className="form-check-input"
                        checked={row.made}
                        onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.checked)}
                        disabled={
                          row.unfinished || state.processes.some((x) => x.weldId === row.weldId && x.deposited !== true)
                        }
                      />
                    </div>
                  )}
                </td>
                <td>
                  {isGroupRow(row) && (
                    <div className="form-check form-switch d-flex justify-content-center">
                      <input
                        type="checkbox"
                        id="visual"
                        className="form-check-input"
                        checked={row.visual}
                        onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.checked)}
                        disabled={row.made !== true}
                      />
                    </div>
                  )}
                </td>
                <td>
                  {isGroupRow(row) && (
                    <div className="form-check form-switch d-flex justify-content-center">
                      <input
                        type="checkbox"
                        id="unfinished"
                        className="form-check-input"
                        checked={row.unfinished}
                        onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.checked)}
                        disabled={
                          row.made || !state.processes.some((x) => x.weldId === row.weldId && x.deposited === true)
                        }
                      />
                    </div>
                  )}
                </td>
                <td>
                  {isGroupRow(row) && (
                    <div className="form-check form-switch d-flex justify-content-center">
                      <input
                        type="checkbox"
                        id="tigWash"
                        className="form-check-input"
                        checked={row.tigWash}
                        onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.checked)}
                        disabled={row.made !== true && row.unfinished !== true}
                      />
                    </div>
                  )}
                </td>
                <td>
                  {isGroupRow(row) && (
                    <div className="form-check form-switch d-flex justify-content-center">
                      <input
                        type="checkbox"
                        id="grind"
                        className="form-check-input"
                        checked={row.grind}
                        onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.checked)}
                        disabled={row.made !== true && row.unfinished !== true}
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PageControls showBack allowBack={!save.isLoading}>
        <PageButton
          onClick={() => modal.show(<SaveChangesDialog onAccept={() => save.mutate(state.processes)} />)}
          loading={save.isLoading}
        >
          Save and Finish
        </PageButton>
      </PageControls>
    </>
  );
};
