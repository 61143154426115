import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ModalProvider } from "providers";
import { queryClient, QueryClientProvider } from "lib/react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ScrollToTop } from "@components/scrolling/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<p>Something went wrong :(</p>}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ModalProvider>
            <App />
            <ScrollToTop />
            <ReactQueryDevtools position="bottom-left" />
          </ModalProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);
