import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { useMenuOptions } from "hooks/useMenuOptions";

export function SideMenu() {
  const options = useMenuOptions();

  return (
    <div className="d-flex flex-column flex-shrink-0 bg-light sidebar-nav">
      <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
        {options
          .filter((x) => x.visible)
          .map((props, index) => (
            <li key={index}>
              <NavLink
                to={props.to}
                title={props.title}
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                className={({ isActive }) =>
                  isActive ? "nav-link py-3 active border-bottom rounded-0" : "nav-link py-3 border-bottom rounded-0"
                }
              >
                <FontAwesomeIcon icon={props.icon} size="lg" />
              </NavLink>
            </li>
          ))}
      </ul>
    </div>
  );
}
