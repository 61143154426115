import { CertificateFilters } from "../types/CertificateFilters";
import { faEraser, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

const initialState: CertificateFilters = {
  welderName: "",
  welderId: "",
  stampNumber: "",
};

export function FilterPanel() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<CertificateFilters>({
    welderName: searchParams.get("welderName") || initialState.welderName,
    welderId: searchParams.get("welderId") || initialState.welderId,
    stampNumber: searchParams.get("stampNumber") || initialState.stampNumber,
  });

  function handleReset() {
    setFilters(initialState);
    setSearchParams();
  }

  function handleSearch(e: React.FormEvent) {
    e.preventDefault();
    setSearchParams(new URLSearchParams(filters));
  }

  function handleFilterChanged(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const fieldName = e.currentTarget.id;
    const newValue = e.currentTarget.value;

    setFilters((prev) => ({ ...prev, [fieldName]: newValue }));
  }

  return (
    <form onSubmit={handleSearch}>
      <button className="btn btn-default search" title="Apply filter">
        Apply filter <FontAwesomeIcon icon={faFilter} />
      </button>
      <button type="button" onClick={handleReset} className="btn btn-light clear" title="Clear filter">
        Clear <FontAwesomeIcon icon={faEraser} />
      </button>
      <hr />

      <div className="mb-2">
        <label htmlFor="welderName" className="form-label">
          Welder name
        </label>
        <input className="form-control" id="welderName" value={filters.welderName} onChange={handleFilterChanged} />
      </div>

      <div className="mb-2">
        <label htmlFor="welderId" className="form-label">
          Welder ID
        </label>
        <input className="form-control" id="welderId" value={filters.welderId} onChange={handleFilterChanged} />
      </div>

      <div className="mb-2">
        <label htmlFor="welderStamp" className="form-label">
          Welder stamp
        </label>
        <input className="form-control" id="stampNumber" value={filters.stampNumber} onChange={handleFilterChanged} />
      </div>
    </form>
  );
}
