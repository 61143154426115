import React from "react";
import { CertificateCard } from "./CertificateCard";
import { CertificateSummary } from "../types/CertificateSummary";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetWelderCard } from "../queries";
import { useState } from "react";
import { Welder } from "@entities";

export function WelderCard(props: { result: Welder }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const certificates = useGetWelderCard(props.result.welderId, isExpanded);

  let _lastRecordStandardGroup = "";

  function onExpandCollapse(e: React.MouseEvent) {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  }

  function isHeaderRequired(summary: CertificateSummary) {
    const result = summary.recordStandardGroup !== _lastRecordStandardGroup;
    _lastRecordStandardGroup = summary.recordStandardGroup;
    return result;
  }

  return (
    <div className={isExpanded ? "card expanded" : "card"}>
      <div className="card-header">
        {props.result.name}
        <span className="badge bg-success">Active</span>
      </div>
      <div className="card-body">
        <img className="photo" src={props.result.picture} />
        {props.result.companyName && props.result.divisionName && (
          <h6 className="card-subtitle mb-2 text-muted">
            {props.result.companyName} / {props.result.divisionName}
          </h6>
        )}
        {!props.result.companyName ||
          (!props.result.divisionName && (
            <h6 className="card-subtitle mb-2 text-muted">
              {props.result.companyName}
              {props.result.divisionName}
            </h6>
          ))}
        <p className="card-text">
          <span>
            <label>Welder ID</label> {props.result.badgeNumber}
          </span>
          <span>
            <label>Stamp Number</label> {props.result.stampNumber}
          </span>
        </p>
        {!isExpanded && (
          <a href="#" onClick={onExpandCollapse} className="btn btn-default">
            View certifications <FontAwesomeIcon icon={faChevronDown} />
          </a>
        )}
        {isExpanded && (
          <a href="#" onClick={onExpandCollapse} className="btn btn-default">
            Hide certifications <FontAwesomeIcon icon={faChevronUp} />
          </a>
        )}
        {certificates.isLoading && <div>Loading...</div>}

        {isExpanded && certificates.isSuccess && certificates.data.length > 0 && (
          <ul className="list-group list-group-flush">
            {certificates.data.map((x, index) => (
              <React.Fragment key={index}>
                {isHeaderRequired(x) && (
                  <li className="list-group-item active">{x.recordStandardGroup}</li>
                )}
                <CertificateCard data={x} />
              </React.Fragment>
            ))}
          </ul>
        )}

        {isExpanded && certificates.isSuccess && certificates.data.length === 0 && (
          <span className="list-group">No certificates available for this welder</span>
        )}
      </div>
    </div>
  );
}
