import { InputHTMLAttributes } from "react";
import { useWpsList } from "@queries/menu.queries";

type WPSLookupProps = InputHTMLAttributes<HTMLInputElement> & {
  process: string;
};

export function WPSLookup({ process, ...props }: WPSLookupProps) {
  const lookup = useWpsList(process);
  const key = `wpsList_${process}`;

  return (
    <>
      <input type="text" className="form-select" list={key} {...props} />
      <datalist id={key}>
        {lookup.data?.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
    </>
  );
}
