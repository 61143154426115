import { Datasheet, Project, SubProject } from "@entities";

export type Weld = {
  weldId: string;
  weldNumber: string;
  jointType: string;
  sapOperation: string;
  performanceClass: string;
  madeDate: Date | null;
  techniques: string;
  drawingId: string;
};

export type Inspection = {
  weldId: string;
  weldNumber: string;
  jointType: string;
  drawingId: string;
  technique: string;
  accept?: boolean;
  defect: string;
  attachment?: File;
  comment: string;
};

export type Ndt = {
  discipline: string;
};

export type WeldListFilters = {
  onlyWeldsReadyForNdt: boolean;
  onlyRepairWelds: boolean;
  onlyWeldsFromSequence: boolean;
};

export type InspectorState = {
  project?: Project;
  subProject?: SubProject;
  datasheet?: Datasheet;
  welds: Weld[];
  inspections: Inspection[];
  filters: WeldListFilters;
};

export const defaultFilters: WeldListFilters = {
  onlyWeldsReadyForNdt: true,
  onlyRepairWelds: false,
  onlyWeldsFromSequence: false,
};

export const defaultState: InspectorState = {
  welds: [],
  inspections: [],
  filters: defaultFilters,
};

export type StateType = "Project" | "SubProject" | "Datasheet" | "Welds" | "Inspections" | "Filters";
