import { PasswordSettings } from "./components/PasswordSettings";
import { ReactElement } from "react";
import { SignatureSettings } from "./components/SignatureSettings";
import { useConfiguration } from "@queries/configuration.queries";

type SettingsTab = {
  id: string;
  caption: string;
  component: ReactElement;
  visible: boolean;
};

export function Settings() {
  const config = useConfiguration();

  const sections: SettingsTab[] = [
    {
      id: "password",
      caption: "Password",
      component: <PasswordSettings />,
      visible: true,
    },
    {
      id: "signatures",
      caption: "Signatures",
      component: <SignatureSettings />,
      visible: Boolean(config.data?.qualifier),
    },
  ];

  return (
    <div className="ps-4">
      <h3>Settings</h3>

      <ul className="nav nav-tabs mt-4">
        {sections
          .filter((x) => x.visible)
          .map((tab, index) => (
            <li key={index} className="nav-item">
              <button
                type="button"
                className={index === 0 ? "nav-link active" : "nav-link"}
                data-bs-toggle="tab"
                data-bs-target={`#${tab.id}`}
              >
                {tab.caption}
              </button>
            </li>
          ))}
      </ul>

      <div className="tab-content ms-4 pt-4">
        {sections
          .filter((x) => x.visible)
          .map((tab, index) => (
            <div key={index} id={tab.id} className={index === 0 ? "tab-pane fade show active" : "tab-pane fade"}>
              {tab.component}
            </div>
          ))}
      </div>
    </div>
  );
}
