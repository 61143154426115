import { Page } from "./../util/Page";
import { String } from "./../util/String";
import { Token } from "@entities/Token";

export class ServiceClient {
    public static authenticate = async (url: string, data: object, completeCallback: any, successCallback: any, failureCallback: any) => {
        await ServiceClient.query("POST", url, data, completeCallback, successCallback, failureCallback, false);
    }

    public static get = async (url: string, completeCallback: any, successCallback: any, failureCallback: any) => {
        await ServiceClient.query("GET", url, null, completeCallback, successCallback, failureCallback, true);
    }

    public static post = async (url: string, data: object, completeCallback: any, successCallback: any, failureCallback: any) => {
        await ServiceClient.query("POST", url, data, completeCallback, successCallback, failureCallback, true);
    }

    public static delete = async (url: string, completeCallback: any, successCallback: any, failureCallback: any) => {
        await ServiceClient.query("DELETE", url, null, completeCallback, successCallback, failureCallback, true);
    }

    public static redirectWithAuth = (url: string, parameters: string|null = null) => {
        ServiceClient.reauthenticate(function () {
            const token = ServiceClient.getToken();
            const uri = parameters ? url + "?" + parameters + "&" : url + "?";
            location.assign("/api/" + uri + "bearer=" + token.accessToken);
        }, 1);
    }

    public static getToken = () => {
        const token = new Token();

        const localUserIds = localStorage.getItem("u_token");
        token.userIds = localUserIds !== null ? JSON.parse(localUserIds) as Record<string, string> : {};
        token.accessToken = localStorage.getItem("a_token");
        token.refreshToken = localStorage.getItem("r_token");

        if (localUserIds === null || JSON.stringify(localUserIds) === "{}") {
            const sessionUserIds = sessionStorage.getItem("u_token");
            token.userIds = sessionUserIds !== null ? JSON.parse(sessionUserIds) as Record<string, string> : {};
            token.accessToken = sessionStorage.getItem("a_token");
            token.refreshToken = sessionStorage.getItem("r_token");
        }

        return token;
    }

    public static isAuthenticated = () => {
        const token = ServiceClient.getToken();
        return token.userIds &&
            token.refreshToken !== null && token.refreshToken.length > 0;
    };

    public static setToken = (token: Token, useSession: boolean) => {
        ServiceClient.clearToken();
        const storage = useSession ? sessionStorage : localStorage;

        storage.setItem("u_token", JSON.stringify(token.userIds));
        storage.setItem("a_token", token.accessToken || "");
        storage.setItem("r_token", token.refreshToken || "");
    }

    public static clearToken = () => {
        localStorage.removeItem("u_token");
        localStorage.removeItem("a_token");
        localStorage.removeItem("r_token");

        sessionStorage.removeItem("u_token");
        sessionStorage.removeItem("a_token");
        sessionStorage.removeItem("r_token");
    }

    private static query = async (method: string, url: string, data: object|null, completeCallback: any, successCallback: any, failureCallback: any, allowReauth: boolean) => {
        const token = ServiceClient.getToken();

        const headers = new Headers();
        headers.set("Content-Type", "application/json;charset=utf-8");
        if (!String.isNullOrWhiteSpace(token.accessToken)) {
            headers.set("Authorization", "bearer " + token.accessToken);
        }

        const response = await fetch("/api/" + url,
            {
                method: method,
                credentials: 'include',
                headers: headers,
                body: data ? JSON.stringify(data) : null
            });

        if (response.ok) {
            successCallback(response.status !== 204 ? await response.json() : undefined, response.status, response.headers);
        } else if (failureCallback) {
            if (response.status === 401 && allowReauth) {
                ServiceClient.reauthenticate(() => ServiceClient.query(method, url, data, completeCallback, successCallback, failureCallback, allowReauth), 1);
            } else {
                failureCallback(response);
            }
        }

        if (completeCallback) {
            completeCallback();
        }
    }

    public static isSession = () => {
        const userId = sessionStorage.getItem("u_token");
        return userId !== null && userId.length > 0;
    }

    public static reauthenticate = async (callback: any, attempt: number) => {
        const token = ServiceClient.getToken();

        if (token.userIds &&
            token.refreshToken !== null && token.refreshToken.length > 0) {

            const response = await fetch("/api/tokens",
                {
                    method: "POST",
                    credentials: 'include',
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                        "Authorization": String.isNullOrWhiteSpace(token.accessToken) ? "" : "bearer " + token.accessToken,
                    },
                    body: JSON.stringify({ userIds: token.userIds, refreshToken: token.refreshToken, grantType: "RefreshToken" })
                });

            if (response.ok) {
                ServiceClient.setToken(await response.json(), ServiceClient.isSession());
                callback();
            } else if (attempt > 2) {
                ServiceClient.clearToken();
                Page.redirect();
            } else {
                attempt++;
                ServiceClient.reauthenticate(callback, attempt);
            }
        } else {
            ServiceClient.clearToken();
            Page.redirect();
        }
    }

    public static logout() {
        ServiceClient.delete(
            "tokens",
            () => { },
            () => { },
            () => { }
        );

        ServiceClient.clearToken();
        Page.redirect("/logout");
    }
}