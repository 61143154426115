import { ConvertToBase64String } from "@utils/Image";
import { useGetUserSignatures, useSaveUserSignature } from "@queries/user.queries";

export function SignatureSettings() {
  const signatures = useGetUserSignatures();
  const saveSignatures = useSaveUserSignature();

  async function selectSignature(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    if (e.target.files) {
      const base64 = await ConvertToBase64String(e.target.files[0]);
      setSignature(index, base64);
    }
  }

  function setSignature(index: number, image: string) {
    if (signatures.data) {
      const signature = { ...signatures.data[index], signature: image };
      saveSignatures.mutate(signature);
    }
  }

  return (
    <div>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column">
          Signature 1
          <img
            src={signatures.data && signatures.data.length > 0 ? signatures.data[0].signature : ""}
            width="150"
            height="50"
            className="user-signature me-3"
          />
          <span>
            <label role="button" htmlFor="select1" className="link-secondary small me-1">
              Select
            </label>
            <input type="file" id="select1" className="d-none" onChange={(e) => selectSignature(e, 0)} />|
            <a role="button" className="link-secondary small ms-1" onClick={() => setSignature(0, "")}>
              Clear
            </a>
          </span>
        </div>
        <div className="d-flex flex-column">
          Signature 2
          <img
            src={signatures.data && signatures.data.length > 1 ? signatures.data[1].signature : ""}
            width="150"
            height="50"
            className="user-signature me-3"
          />
          <span>
            <label role="button" htmlFor="select2" className="link-secondary small me-1">
              Select
            </label>
            <input type="file" id="select2" className="d-none" onChange={(e) => selectSignature(e, 1)} />|
            <a role="button" className="link-secondary small ms-1" onClick={() => setSignature(1, "")}>
              Clear
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
