import * as api from "api/user";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UserSignature } from "@entities";

export function useCoordinatorUser() {
  return useQuery("user/signatures", api.getCoordinatorUser);
}

export function useGetUserSignatures() {
  return useQuery("user/signatures", api.getUserSignatures, {
    staleTime: Infinity,
  });
}

export function useSaveUserSignature() {
  const queryClient = useQueryClient();

  return useMutation(
    (signature: UserSignature) => {
      const signatures = queryClient.getQueryData<UserSignature[]>("user/signatures");
      if (signatures) {
        const newSignatures = signatures.map((x) => (x.userSignatureId === signature.userSignatureId ? signature : x));
        queryClient.setQueryData("user/signatures", newSignatures);
      }
      return api.saveUserSignature(signature);
    },
    {
      onSettled: () => queryClient.invalidateQueries("user/signatures"),
    }
  );
}
