import { ReactElement } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

type ModalDialogProps = {
  caption?: string;
  onShow?: () => void;
  onClose: (result: boolean) => void;
  children: ReactElement | ReactElement[];
};

export function ModalDialog(props: ModalDialogProps) {
  return (
    <Modal
      show={true}
      onShow={props.onShow}
      onHide={() => props.onClose(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <strong>{props.caption}</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{props.children}</Modal.Body>

      <Modal.Footer>
        <Button className="btn btn-default" onClick={() => props.onClose(true)}>
          OK
        </Button>
        <Button className="btn btn-default" onClick={() => props.onClose(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalDialog;
