import { InputHTMLAttributes } from "react";
import { useFillerClassifications } from "@queries/menu.queries";

type FillerMatrixLookupProps = InputHTMLAttributes<HTMLInputElement> & {
  process: string;
  wpsNumber: string;
};

export function FillerMatrixLookup({ process, wpsNumber, ...props }: FillerMatrixLookupProps) {
  const lookup = useFillerClassifications(process, wpsNumber);
  const key = `fillers_${process}_${wpsNumber}`;

  return (
    <>
      <input type="text" className="form-select" list={key} {...props} />
      <datalist id={key}>
        {lookup.data?.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
    </>
  );
}
