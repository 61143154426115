import { HitachiInspectorRole, HitachiWelderRole } from "./hitachi";
import { InspectorRole } from "./inspector";
import { NotFound } from "@components";
import { ParalloyInspectorRole, ParalloyWelderRole } from "./paralloy";
import { RoleSelector } from "./common/RoleSelector";
import { Route, Routes } from "react-router";
import { VersionCheck } from "@components/guards";
import { WelderRole } from "./welder";

export const RoleBasedInterface = () => {
  return (
    <div className="rbi">
      <Routes>
        <Route path="/" element={<RoleSelector />} />
        <Route path="welder" element={<WelderRole />} />
        <Route path="inspector" element={<InspectorRole />} />

        <Route path="paralloy" element={<VersionCheck version="Paralloy" />}>
          <Route path="welder" element={<ParalloyWelderRole />} />
          <Route path="inspector" element={<ParalloyInspectorRole />} />
        </Route>

        <Route path="hitachi" element={<VersionCheck version="Hitachi" />}>
          <Route path="welder" element={<HitachiWelderRole />} />
          <Route path="inspector" element={<HitachiInspectorRole />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};
