import { Datasheet, Project, SubProject } from "@entities";

export type Weld = {
  weldId: string;
  weldNumber: string;
  jointType: string;
  testPack: string;
  inspectionClass: string;
  madeDate: Date | null;
  drawingId: string;
};

export type Process = {
  weldId: string;
  passId: string;
  weldNumber: string;
  process: string;
  wpsNumber: string;
  filler: string;
  heatNumber: string;
  plantNumber: string;
  made: boolean;
  completed: boolean;
};

export type WeldListFilters = {
  onlyMyWelds: boolean;
  onlyWeldsNotMade: boolean;
  onlyRepairWelds: boolean;
};

export type WelderState = {
  project?: Project;
  subProject?: SubProject;
  datasheet?: Datasheet;
  welds: Weld[];
  processes: Process[];
  filters: WeldListFilters;
};

export const defaultFilters: WeldListFilters = {
  onlyMyWelds: false,
  onlyWeldsNotMade: true,
  onlyRepairWelds: false,
};

export const defaultState: WelderState = {
  welds: [],
  processes: [],
  filters: defaultFilters,
};

export type StateType = "Project" | "SubProject" | "Datasheet" | "Welds" | "Processes" | "Filters";
