import { Button, Spinner } from "react-bootstrap";
import { ButtonHTMLAttributes } from "react";

export type SpinButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

export function SpinButton(props: SpinButtonProps) {
  const { loading, children, ...attributes } = props;

  return (
    <Button disabled={loading} {...attributes}>
      {loading && <Spinner as="span" animation="border" size="sm" />} {children}
    </Button>
  );
}

export default SpinButton;
