import { Pagination } from "@components";
import { useEffect, useState } from "react";
import { useGetWelders } from "../queries";
import { useSearchParams } from "react-router-dom";
import { WelderCard } from "./WelderCard";

const RESULTS_PER_PAGE = 15;

export function SearchResults() {
  const [searchParams] = useSearchParams();
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    // Reset selected page when filters change.
    setSkip(0);
  }, [searchParams]);

  const query = useGetWelders(skip, RESULTS_PER_PAGE, searchParams.toString());

  if (query.isLoading) {
    return <span>Loading...</span>;
  }

  if (query.isSuccess && query.data.results.length === 0) {
    return <span>Your search returned no results.</span>;
  }

  return (
    <>
      {query.data?.results.map((welder, index) => (
        <WelderCard key={index} result={welder} />
      ))}

      {query.data && query.data.total > RESULTS_PER_PAGE && (
        <Pagination
          current={query.data.page}
          total={query.data.total}
          count={query.data.results.length}
          pageSize={RESULTS_PER_PAGE}
          onPageChanged={setSkip}
        />
      )}
    </>
  );
}
