import { createContext, Dispatch } from "react";
import { Datasheet, Project, SubProject } from "@entities";
import { Inspection, InspectorState, StateType, Weld, WeldListFilters } from "./types";

export interface IInspectorContext {
  state: InspectorState;
  updateState: Dispatch<RbiAction>;
}

export const InspectorContext = createContext<IInspectorContext>({} as IInspectorContext);

export type RbiAction = {
  type: StateType;
  payload?: Project | SubProject | Datasheet | Weld[] | Inspection[] | WeldListFilters;
};

export const reducer = (state: InspectorState, action: RbiAction): InspectorState => {
  switch (action.type) {
    case "Project":
      return { ...state, project: action.payload as Project, subProject: undefined, datasheet: undefined };
    case "SubProject":
      return { ...state, subProject: action.payload as SubProject, datasheet: undefined };
    case "Datasheet":
      return { ...state, datasheet: action.payload as Datasheet, welds: [], inspections: [] };
    case "Welds":
      return { ...state, welds: action.payload as Weld[], inspections: [] };
    case "Inspections":
      return { ...state, inspections: action.payload as Inspection[] };
    case "Filters":
      return { ...state, filters: action.payload as WeldListFilters, welds: [], inspections: [] };
    default:
      return state;
  }
};
