import * as api from "api/certificates";
import { RecordSignature, UserSignature } from "@entities";
import { useMutation, useQueryClient } from "react-query";

export function useRevalidate(signature: RecordSignature) {
  const queryClient = useQueryClient();
  return useMutation((userSignature?: UserSignature) => api.revalidate(signature, userSignature), {
    onSuccess: () => {
      // Updates the revalidation date (TODO: specify welder ID)
      queryClient.invalidateQueries(["certificates"]);
    },
  });
}
