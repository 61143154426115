import { api } from "@api";
import { Datasheet } from "@entities";
import { Inspection, Weld, WeldListFilters } from "./types";
import { InspectorContext } from "./reducer";
import { Page } from "@utils";
import { useContext } from "react";
import { useMutation, useQuery } from "react-query";

export function useGetWeldList(filters: WeldListFilters, datasheet?: Datasheet) {
  return useQuery(["rbi/inspector/welds", datasheet, filters], () => fetchWeldList(filters, datasheet), {
    enabled: Boolean(datasheet),
  });
}

export function useGetInspections(weldIds: string[]) {
  const route = "rbi/inspector/inspections";
  const { updateState } = useContext(InspectorContext);

  return useQuery([route, weldIds], () => api.post<Inspection[]>(route, weldIds), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      updateState({ type: "Inspections", payload: data });
    },
  });
}

export function useSave() {
  return useMutation(async (inspections: Inspection[]) => {
    await Promise.all([api.post("rbi/inspector", inspections), saveAttachments(inspections)]);
    Page.redirect("rbi/inspector");
  });
}

function saveAttachments(inspections: Inspection[]) {
  return Promise.all(
    inspections.map(async (row) => {
      if (row.attachment) {
        const formData = new FormData();
        formData.append("file", row.attachment);

        await api.post(`welds/${row.weldId}/attachments`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
    })
  );
}

function fetchWeldList(filters: WeldListFilters, datasheet?: Datasheet) {
  const params = new URLSearchParams({
    datasheetId: datasheet?.datasheetId?.toString() ?? "",
    onlyWeldsNotMade: filters.onlyWeldsNotMade.toString(),
    onlyWeldsReadyForNdt: filters.onlyWeldsReadyForNdt.toString(),
    onlyRepairWelds: filters.onlyRepairWelds.toString(),
  });

  return api.get<Weld[]>(`rbi/inspector/welds?${params.toString()}`);
}
