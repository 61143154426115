import { PaginationHelper } from "@entities/Pagination";
import { HTMLAttributes } from "react";

interface PaginationProps extends HTMLAttributes<HTMLElement> {
  current: number;
  count: number;
  total: number;
  pageSize: number;
  onPageChanged: (skip: number) => void;
}

export function Pagination(props: PaginationProps) {
  const pagination = new PaginationHelper(props.count, props.current, props.total, props.pageSize);

  function handlePageChanged(e: React.FormEvent, skip: number) {
    e.preventDefault();
    props.onPageChanged(skip);
  }

  return (
    <div className="pagination">
      <div className="paging">{pagination.paging}</div>
      {pagination.hasData && pagination.lastPage > 1 && (
        <nav aria-label="Page navigation">
          <ul className="pagination pg-blue">
            {pagination.lastPage > 2 && (
              <li className={pagination.pagingPrevious ? "page-item" : "page-item disabled"} title="First page">
                <a className="page-link" href="#" aria-label="First" onClick={(e) => handlePageChanged(e, 0)}>
                  <span aria-hidden="true">&laquo;&laquo;</span>
                  <span className="sr-only">First</span>
                </a>
              </li>
            )}
            <li className={pagination.pagingPrevious ? "page-item" : "page-item disabled"} title="Previous page">
              <a
                className="page-link"
                href="#"
                aria-label="Previous"
                onClick={(e) => handlePageChanged(e, pagination.previousPagingSkip)}
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
            {pagination.currentPage > 5 && (
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  onClick={(e) => handlePageChanged(e, pagination.getSkip(pagination.firstBlock))}
                >
                  ...
                </a>
              </li>
            )}
            {pagination.pages.map((x, index) => (
              <li key={index} className={pagination.currentPage === x ? "page-item active" : "page-item"}>
                <a className="page-link" href="#" onClick={(e) => handlePageChanged(e, pagination.getSkip(x))}>
                  {x}
                </a>
              </li>
            ))}
            {pagination.allowNextBlock && (
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  onClick={(e) => handlePageChanged(e, pagination.getSkip(pagination.lastBlock))}
                >
                  ...
                </a>
              </li>
            )}
            <li className={pagination.pagingNext ? "page-item" : "page-item disabled"} title="Next page">
              <a
                className="page-link"
                href="#"
                aria-label="Next"
                onClick={(e) => handlePageChanged(e, pagination.nextPagingSkip)}
              >
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
            {pagination.lastPage > 2 && (
              <li className={pagination.pagingNext ? "page-item" : "page-item disabled"} title="Last page">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Last"
                  onClick={(e) => handlePageChanged(e, pagination.getSkip(pagination.lastPage))}
                >
                  <span aria-hidden="true">&raquo;&raquo;</span>
                  <span className="sr-only">Last</span>
                </a>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
}
