import { GenericDialog } from "@components/dialogs";
import { useState } from "react";
import { Welder } from "@entities";

export const SaveChangesDialog = (props: { onAccept: () => void }) => {
  return (
    <GenericDialog caption="Save Changes" buttons="OKCancel" onAccept={props.onAccept}>
      All you sure you want to save changes and check-in all welds?
    </GenericDialog>
  );
};

type WelderSelectionDialogProps = {
  welders: Welder[];
  onAccept: (welders: Welder[]) => void;
};

export const WelderSelectionDialog = ({ welders, onAccept }: WelderSelectionDialogProps) => {
  const [selected, setSelected] = useState<Welder[]>(welders);

  function handleWelderSelected(e: React.ChangeEvent<HTMLInputElement>, welder: Welder) {
    if (e.currentTarget.checked) {
      setSelected((prev) => [...prev, welder]);
    } else {
      setSelected((prev) => prev.filter((x) => x.name !== welder.name));
    }
  }

  return (
    <GenericDialog caption="Welder Selection" buttons="OKCancel" onAccept={() => onAccept(selected)}>
      <div>
        <p>Please confirm which welder(s) contributed to this weld:</p>
        <ul className="list-unstyled">
          {welders.map((welder, index) => (
            <li key={index}>
              <input
                type="checkbox"
                className="form-check-input ms-3 me-3"
                onChange={(e) => handleWelderSelected(e, welder)}
                checked={selected.some((x) => x.name === welder.name)}
              />
              {welder.name}
            </li>
          ))}
        </ul>
      </div>
    </GenericDialog>
  );
};
