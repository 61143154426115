import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faListCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

export default class NavigationTree extends React.PureComponent<{ data: any, selectedItemChanged: any }, { nodes: any }> {
    _selectedNode: Node | null = null;

    constructor(props: { data: any, selectedItemChanged: any }, context: any) {
        super(props, context);

        this.selected = this.selected.bind(this);
    }

    selected = (e: Node) => {
        if (!e.props.nodes || e.props.nodes.length === 0) {
            if (this._selectedNode) {
                this._selectedNode.setState({ cssSelectedClass: "" });
            }

            this._selectedNode = e;
            e.setState({ cssSelectedClass: "selected" });
            this.props.selectedItemChanged(e.props);
        }
    }

    createNode = (id: string, name: string, subName: string, breadcrumb: string, isFinalSignOff: boolean) => {
        return {
            id: id,
            name: name,
            subName: subName,
            breadcrumb: breadcrumb,
            isFinalSignOff: isFinalSignOff,
            nodes: []
        }
    }

    addNode = (nodeId: string, projectName: string, unitName: string, datasheetName: string, subName: string, isFinalSignOff: boolean) => {
        if (!projectName || !unitName || !datasheetName) {
            return;
        }

        const path = [projectName, unitName, datasheetName];
        this.appendNode(nodeId, path, subName, path.join(" / "), isFinalSignOff, this.state.nodes);
    }

    appendNode = (id: string, path: string[], subName: string, breadcrumb: string, isFinalSignOff: boolean, childNodes: any) => {
        const name = path[0];
        let found = false;

        for (let i = 0; i < childNodes.length; i++) {
            const node = childNodes[i];
            const nodeName = node.name;

            if (nodeName === name) {
                found = true;

                path.splice(0, 1);

                if (path.length > 0) {
                    this.appendNode(id, path, subName, breadcrumb, isFinalSignOff, node.nodes);
                }

                break;
            } else if (nodeName > name) {
                found = true;

                const newNode:any = this.createNode(id, name, subName, breadcrumb, isFinalSignOff);
                childNodes.splice(i, 0, newNode)
                path.splice(0, 1);

                if (path.length > 0) {
                    this.appendNode(id, path, subName, breadcrumb, isFinalSignOff, newNode.nodes);
                }

                break;
            }
        }

        if (!found) {
            const newNode1:any = this.createNode(id, name, subName, breadcrumb, isFinalSignOff);
            childNodes.splice(childNodes.length, 0, newNode1);
            path.splice(0, 1);

            if (path.length > 0) {
                this.appendNode(id, path, subName, breadcrumb, isFinalSignOff, newNode1.nodes);
            }
        }
    }

    public render() {
        this.state = {
            nodes: []
        }

        if (!this.props.data) {
            return (<ul className="tree"></ul>);
        }

        for (const entry of this.props.data) {
            const subText = !entry.subTextName ? "(Not specified)" : entry.subTextName;
            this.addNode(entry.datasheetId, entry.projectName, entry.unitName, entry.datasheetName, "Job Number: " + subText, entry.isFinalSignOff);
        }

        return (
            <ul className="tree">
                {this.state.nodes.map((x: { id: string, name: string, subName: string, breadcrumb: string, isFinalSignOff: boolean, nodes: any }) => (
                    <Node key={x.id} id={x.id} name={x.name} subName={x.subName} breadcrumb={x.breadcrumb} isFinalSignOff={x.isFinalSignOff} nodes={x.nodes} selected={this.selected} />
                ))}
            </ul>
            )
    }
}

class Node extends React.PureComponent<{ id: string, name: string, subName: string, breadcrumb: string, isFinalSignOff: boolean, nodes: any, selected: any }, { isOpen: boolean, cssSelectedClass: string, isFinalSignOff: boolean }> {
    constructor(props: { id: string, name: string, subName: string, breadcrumb: string, isFinalSignOff: boolean, nodes: any, selected: any }, context: any) {
        super(props, context);
        this.state = {
            isOpen: false,
            cssSelectedClass: "",
            isFinalSignOff: props.isFinalSignOff
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });

        this.props.selected(this);
    }

    hasNodes = (nodes:any) => {
        return nodes && nodes.length > 0;
    }

    render() {
        const { name, nodes } = this.props;
        const { isOpen, isFinalSignOff } = this.state;

        return (
            <li>
                {isOpen && this.hasNodes(nodes) && (
                    <span onClick={this.toggle}>
                        <FontAwesomeIcon icon={faCaretDown} />
                        <span>{name}</span>
                    </span>
                )}
                {!isOpen && this.hasNodes(nodes) && (
                    <span onClick={this.toggle}>
                        <FontAwesomeIcon icon={faCaretRight} />
                        <span>{name}</span>
                    </span>
                )}
                {isFinalSignOff && !this.hasNodes(nodes) && (
                    <span onClick={this.toggle}>
                        <FontAwesomeIcon icon={faCircleCheck} title="Final signoff" />
                        <span className={this.state.cssSelectedClass}>{name}</span>
                    </span>
                )}
                {!isFinalSignOff && !this.hasNodes(nodes) && (
                    <span onClick={this.toggle}>
                        <FontAwesomeIcon icon={faListCheck} title="Hold point signoff" />
                        <span className={this.state.cssSelectedClass}>{name}</span>
                    </span>
                )}

                {this.hasNodes(nodes) && isOpen && (
                    <ul>
                        {nodes.map((props: {id: string, name: string, subName: string, breadcrumb: string, isFinalSignOff: boolean, nodes: any}) => (
                            <Node key={props.id} id={props.id} name={props.name} subName={props.subName} breadcrumb={props.breadcrumb} isFinalSignOff={props.isFinalSignOff} nodes={props.nodes} selected={this.props.selected} />
                        ))}
                    </ul>
                )}
            </li>
        );
    }
}