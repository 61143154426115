import { Datasheet, Project, SubProject } from "@entities";
import { ParalloyState, StateType, Weld } from "./types";

export type RbiAction = {
  type: StateType;
  payload?: unknown;
};

export const reducer = (state: ParalloyState, action: RbiAction): ParalloyState => {
  switch (action.type) {
    case "Project":
      return { ...state, project: action.payload as Project, subProject: undefined, datasheet: undefined, welds: [] };
    case "SubProject":
      return { ...state, subProject: action.payload as SubProject, datasheet: undefined, welds: [] };
    case "Datasheet":
      return { ...state, datasheet: action.payload as Datasheet, welds: [] };
    case "Welds":
      return { ...state, welds: action.payload as Weld[] };
    default:
      return state;
  }
};
