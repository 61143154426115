import * as api from "api/record";
import { RecordSignature, UserSignature } from "@entities";
import { ServiceClient } from "@utils";
import { useMutation, useQuery, useQueryClient } from "react-query";

export function useGetRecordSignatures(recordId: string) {
  const queryKey = ["signatures", recordId];
  return useQuery(queryKey, () => api.getRecordSignatures(recordId));
}

export function useSignSignature(signature: RecordSignature) {
  const queryClient = useQueryClient();
  return useMutation((userSignature?: UserSignature) => api.signSignature(signature, userSignature), {
    onSuccess: () => {
      queryClient.invalidateQueries(["signatures", signature.recordId]);
    },
  });
}

export function useGetRecordAttachments(recordId: string) {
  const queryKey = ["attachments", recordId];
  return useQuery(queryKey, () => api.getRecordAttachments(recordId));
}

export function useGetReferenceDocuments() {
  const isLoggedIn = ServiceClient.isAuthenticated();
  return useQuery("references", api.getReferenceDocuments, {
    enabled: isLoggedIn,
  });
}
