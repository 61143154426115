import * as React from "react";
import { IsoDrawing, IsoDrawingWeld } from "@entities";
import { useEffect, useState } from "react";
import { useGetDrawingById } from "./queries";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { WeldInformation } from "./components/WeldInformation";

const markerSize = 7;

export function IsometricDrawing() {
  const [selectedWeld, setSelectedWeld] = useState<IsoDrawingWeld>();
  const [showWeldPopup, setShowWeldPopup] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const drawing = useGetDrawingById(id);
  const weldId = searchParams.get("weldId");
  const drawingRef = React.useRef(drawing.data);

  let scaleFactor = 1;

  useEffect(() => {
    if (drawing.data) {
      drawingRef.current = drawing.data;
      renderDrawing(drawing.data);
    }
  }, [drawing.isSuccess]);

  function renderDrawing(drawing: IsoDrawing) {
    const canvas = document.getElementById("drawing") as HTMLCanvasElement;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        const img = new Image();
        img.src = drawing.drawingImage;
        img.onload = function (e) {
          scaleImage(img, canvas);
          context.drawImage(img, 0, 0, img.width, img.height);
          drawWeldMarkers(drawing, context);
        };
      }
      canvas.addEventListener("click", onMouseClick);
      window.addEventListener("resize", onResize);
    }
  }

  function scaleImage(img: HTMLImageElement, canvas: HTMLCanvasElement) {
    canvas.width = window.innerWidth * 0.6;
    canvas.height = window.innerHeight * 0.6;

    scaleFactor = Math.min(canvas.width / img.width, canvas.height / img.height);

    img.width = img.width * scaleFactor;
    img.height = img.height * scaleFactor;
  }

  function onResize() {
    if (drawingRef.current) {
      renderDrawing(drawingRef.current);
    }
  }

  function drawWeldMarkers(drawing: IsoDrawing, context: CanvasRenderingContext2D) {
    for (let i = 0; i < drawing.welds.length; i++) {
      const weld = drawing.welds[i];

      let size = markerSize;

      if (weld.weldId === weldId) {
        size *= 1.5;
      }

      if (weld.visibleOnDrawing) {
        const circle = new Path2D();
        const X = weld.positionX * scaleFactor;
        const Y = weld.positionY * scaleFactor;

        circle.arc(X, Y, size, 0, 2 * Math.PI, false);

        if (weld.weldCompletedDate) context.fillStyle = "green";
        else if (weld.weldMadeDate) context.fillStyle = "blue";
        else context.fillStyle = "red";

        context.fill(circle);
      }
    }
  }

  function onMouseClick(this: HTMLCanvasElement, mouse: MouseEvent) {
    const positionX = mouse.x - this.offsetLeft - this.clientLeft;
    const positionY = mouse.y - this.offsetTop - this.clientTop;

    if (drawingRef.current) {
      const selectedWeld = drawingRef.current.welds.find((weld) => isWeldAtPosition(weld, positionX, positionY));

      if (selectedWeld) {
        onWeldClicked(selectedWeld);
      }
    }
  }

  function isWeldAtPosition(weld: IsoDrawingWeld, positionX: number, positionY: number) {
    const dx = weld.positionX * scaleFactor - positionX;
    const dy = weld.positionY * scaleFactor - positionY;

    return dx * dx + dy * dy <= markerSize * markerSize;
  }

  function onWeldClicked(weld: IsoDrawingWeld) {
    setSelectedWeld(weld);
    setShowWeldPopup(true);
  }

  return (
    <div className="d-flex justify-content-center mx-auto">
      <canvas id="drawing" />

      {showWeldPopup && selectedWeld && (
        <WeldInformation
          selectedWeld={selectedWeld}
          caption={`Weld Number: ${selectedWeld?.weldNumber}`}
          onClose={() => setShowWeldPopup(false)}
        />
      )}
    </div>
  );
}
