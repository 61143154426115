import { addProtocolToUrl, DateTime, ServiceClient } from "@utils";
import { AttachmentFormat } from "@entities";
import { CertificateSummary } from "../types/CertificateSummary";
import { faChevronDown, faChevronUp, faFileContract, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SignatureEntry } from "@components/record/SignatureEntry";
import { useGetRecordAttachments, useGetRecordSignatures } from "@queries/record.queries";
import { useState } from "react";

export function CertificateCard(props: { data: CertificateSummary }) {
  const [expanded, setExpanded] = useState(false);

  const signatures = useGetRecordSignatures(props.data.recordId);
  const attachments = useGetRecordAttachments(props.data.recordId);
  const showSignatures = signatures.isSuccess && signatures.data.length > 0;
  const showAttachments = attachments.isSuccess && attachments.data.length > 0;
  const showExpandButton = showSignatures || showAttachments || props.data.canRevalidate;

  function onViewCertificate(e: React.MouseEvent, recordId: string) {
    e.preventDefault();
    ServiceClient.redirectWithAuth(`certificates/${recordId}`);
  }

  function downloadAttachment(e: React.MouseEvent, attachmentId: string) {
    e.preventDefault();
    ServiceClient.redirectWithAuth(`record/${props.data.recordId}/attachments/${attachmentId}`);
  }

  return (
    <li className="list-group-item">
      <div className="card-text detail">
        <div className="d-flex justify-content-between flex-wrap">
          <div>
            <FontAwesomeIcon icon={faFileContract} className="me-2" />
            <a href="#" onClick={(e) => onViewCertificate(e, props.data.recordId)} title="View certificate">
              {props.data.recordNumber}
            </a>
            <div>
              {props.data.processes && (
                <div>
                  {props.data.processes} {props.data.matProductForms && <>({props.data.matProductForms})</>}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex flex-column text-start text-sm-end">
            {props.data.testDate && <div>Tested on {DateTime.getLocalDate(props.data.testDate)}</div>}
            {props.data.expiryDate && <div>Revalidation by {DateTime.getLocalDate(props.data.expiryDate)}</div>}
            {showExpandButton && (
              <a className="link-primary" onClick={() => setExpanded((prev) => !prev)}>
                <div>
                  {expanded ? "Hide" : "Show more"}
                  <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} className="ms-2" />
                </div>
              </a>
            )}
          </div>
        </div>
        <div>
          {expanded && (
            <div className="d-flex flex-column flex-sm-row flex-wrap justify-content-between mb-3">
              <div className="mt-3">
                <div className="fw-bold">Attachments</div>
                <ul className="list-unstyled mt-0">
                  {!showAttachments && <div>No attachments found</div>}
                  {showAttachments &&
                    attachments.data.map((attachment, index) => (
                      <li key={index}>
                        <FontAwesomeIcon icon={faPaperclip} size="xs" />
                        {attachment.attachmentFormat === AttachmentFormat.File && (
                          <a
                            href="#"
                            onClick={(e) => downloadAttachment(e, attachment.attachmentId)}
                            title="Download Attachment"
                            className="ms-2"
                          >
                            {attachment.fileName}
                          </a>
                        )}
                        {attachment.attachmentFormat === AttachmentFormat.URL && (
                          <a
                            href={addProtocolToUrl(attachment.filePath)}
                            title={attachment.filePath}
                            target="_blank"
                            rel="noreferrer"
                            className="ms-2"
                          >
                            {attachment.fileName}
                          </a>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="d-flex flex-column flex-sm-row flex-wrap gap-sm-5 gap-0">
                {props.data.canRevalidate && (
                  <div className="mt-3">
                    <div className="fw-bold">2-Year Revalidation</div>
                    <SignatureEntry
                      type="Revalidation"
                      signature={{
                        recordId: props.data.recordId,
                        title: "Signature",
                        signed: false,
                      }}
                    />
                  </div>
                )}
                {showSignatures && (
                  <div className="mt-3">
                    <div className="fw-bold">Signatures</div>
                    {signatures.data.map((signature, index) => (
                      <SignatureEntry type="Record" key={index} signature={signature} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </li>
  );
}
