import { ButtonHTMLAttributes, ReactElement } from "react";
import { useModal } from "hooks";

import styles from "./GenericDialog.module.css";

type ButtonConfig = "OK" | "OKCancel" | "YesNoCancel";

type GenericDialogProps = {
  caption: string;
  buttons: ButtonConfig;
  children: ReactElement | string;
  onAccept?: () => void;
  onCancel?: () => void;
};

const Button = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button type="button" className="btn btn-default me-1" {...props}>
    {props.children}
  </button>
);

export const GenericDialog = (props: GenericDialogProps) => {
  const modal = useModal();

  function handleAccept() {
    modal.close();
    if (props.onAccept) {
      props.onAccept();
    }
  }

  function handleCancel() {
    modal.close();
    if (props.onCancel) {
      props.onCancel();
    }
  }
  return (
    <div>
      <h5 className={styles.header}>{props.caption}</h5>
      <div className={styles.body}>{props.children}</div>
      <div className={styles.footer}>
        {props.buttons === "OK" && (
          <>
            <Button onClick={handleAccept}>OK</Button>
          </>
        )}
        {props.buttons === "OKCancel" && (
          <>
            <Button onClick={handleAccept}>OK</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </>
        )}
        {props.buttons === "YesNoCancel" && (
          <>
            <Button onClick={handleAccept}>Yes</Button>
            <Button onClick={handleCancel}>No</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </>
        )}
      </div>
    </div>
  );
};
