export class PaginationHelper {
  pageSize: number;
  paging: string = "";
  nextPagingSkip: number = 0;
  previousPagingSkip: number = 0;
  pagingPrevious: boolean = false;
  pagingNext: boolean = false;
  allowNextBlock: boolean = false;
  pages: number[] = [];
  firstBlock: number = 1;
  lastBlock: number = 5;
  lastPage: number = 1;
  currentPage: number = 1;
  hasData: boolean = false;

  public constructor(count: number, page: number, total: number, pageSize: number) {
    this.pageSize = pageSize;
    this.currentPage = page;
    this.nextPagingSkip = page * this.pageSize;
    this.previousPagingSkip = page * this.pageSize - this.pageSize * 2;
    const pageTo = Math.ceil(total / this.pageSize);
    this.paging = !page || !pageTo ? "" : "Page " + page + " of " + pageTo;

    this.lastPage = pageTo;
    this.hasData = count > 0;
    this.pagingPrevious = page > 1;
    this.pagingNext = page * this.pageSize < total;

    let toPage = page;
    if (toPage % 5 !== 0) {
      for (let k = toPage; k <= pageTo; k++) {
        toPage = k;
        if (toPage % 5 === 0) {
          break;
        }
      }
    }

    let fromPage = 1;
    for (let k = toPage; k >= toPage - 4 && k >= 1; k--) {
      fromPage = k;
    }

    this.firstBlock = fromPage - 1;
    this.lastBlock = toPage + 1;
    this.allowNextBlock = pageTo > toPage;

    this.pages = [];
    for (let j = fromPage; j <= toPage; j++) {
      this.pages.push(j);
    }
  }

  getSkip(page: number) {
    return this.pageSize * (page - 1);
  }
}
