import { api } from "@api";
import { RecordAttachment, RecordSignature, ReferenceDocument, UserSignature } from "@entities";

export function getRecordSignatures(recordId: string) {
  return api.get<RecordSignature[]>(`record/${recordId}/signatures`);
}

export function signSignature(signature: RecordSignature, userSignature?: UserSignature) {
  return api.post(`record/sign/${signature.signatureId}`, {
    userSignatureId: userSignature?.userSignatureId,
  });
}

export function getRecordAttachments(recordId: string) {
  return api.get<RecordAttachment[]>(`record/${recordId}/attachments`);
}

export function getReferenceDocuments() {
  return api.get<ReferenceDocument[]>("references");
}
