import { api } from "@api";
import { CheckoutWeldsResponse, ParalloyState, Weld } from "./types";
import { ServiceClient } from "@utils";
import { useMutation, useQuery } from "react-query";

export function useWeldList(state: ParalloyState, skip: number, take: number) {
  const route = "paralloy/rbi/welds";
  const params = getSearchParams(state, skip, take);

  return useQuery([route, params], () => api.getPaginated<Weld[]>(`${route}?${params}`));
}

export function useWeldCheckout() {
  return useMutation((welds: Weld[]) =>
    api.post<CheckoutWeldsResponse>(
      "paralloy/rbi/checkout",
      welds.map((x) => x.weldId)
    )
  );
}

export function useCheckedOutWelds() {
  const route = "paralloy/rbi/checkout";
  return useQuery([route], () => api.get<Weld[]>(route));
}

export function useSave() {
  return useMutation(async (welds: Weld[]) => {
    await api.post("paralloy/rbi/welds", welds);
    ServiceClient.logout();
  });
}

function getSearchParams(state: ParalloyState, skip: number, take: number) {
  const params = new URLSearchParams();

  params.append("skip", skip.toString());
  params.append("take", take.toString());
  params.append("onlyWeldsNotMade", "true");

  if (state.subProject?.id) {
    params.append("subProjectId", state.subProject.id);
  }
  if (state.datasheet?.datasheetId) {
    params.append("datasheetId", state.datasheet.datasheetId);
  }

  return params.toString();
}
