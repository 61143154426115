import { api } from "@api";
import { LoginCredentials } from "@entities";
import { PasswordChangeRequest } from "schemas/password.schema";

export function login(credentials: LoginCredentials) {
  return api.authenticate(credentials);
}

export function changePassword(request: PasswordChangeRequest) {
  return api.post("/user/change-password", request);
}
