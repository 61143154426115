import * as React from "react";
import { IndexState } from "./state/IndexState";
import { Page } from "@utils/Page";
import { ServiceClient } from "@utils/ServiceClient";
import { String } from "@utils/String";
import { Token } from "@entities/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

export class Login extends React.PureComponent<{}, IndexState> {
    _userId: HTMLInputElement | null = null;
    _resetToken: Token | null = null;
    _resetUseSession = false;

    constructor(props: {}, context: any) {
        super(props, context);
        this.state = new IndexState();

        this.login = this.login.bind(this);
        this.requestReset = this.requestReset.bind(this);
        this.applyReset = this.applyReset.bind(this);
    }

    componentDidMount() {
        this.setState({ isReset: this.getResetToken() !== null });

        if (this._userId) {
            this._userId.focus();
        }

        this.redirect();
    }

    redirect() {
        if (ServiceClient.isAuthenticated()) {
            ServiceClient.get(
                "configurations",
                () => { },
                (data: any) => {
                    const coordinatorUrl = data?.coordinator?.isAep ? "datasheets" : "rbi";
                    Page.redirect(data?.qualifier !== null ? "certificates" : coordinatorUrl);
                },
                () => { });
        }
    }

    login(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        this.setState({ isAuthenticating: true, isAuthenticationError: false, isAuthenticationFailure: false, isResetAccepted: false, isUserIdRequired: false });

        const useSession = !Page.getInputChecked(e, "rememberMe");

        ServiceClient.authenticate(
            "tokens",
            { grantType: "Password", userId: Page.getInputValue(e, "userId"), password: Page.getInputValue(e, "password") },
            () => {},
            (token: Token, status: number) => {
                if (status === 202) {
                    ServiceClient.clearToken();
                    this._resetUseSession = useSession;
                    this._resetToken = token;
                    this.setState({ isAuthenticating: false, isAuthenticationError: false, isAuthenticationFailure: false, isReset: true });
                } else {
                    ServiceClient.setToken(token, useSession);
                    this.redirect();
                }
            },
            (error: Response) => {
                if (error.status === 401) {
                    this.setState({ isAuthenticating: false, isAuthenticationError: false, isAuthenticationFailure: true });
                } else {
                    this.setState({ isAuthenticating: false, isAuthenticationError: true });
                }
            });
    }

    requestReset(e: any) {
        e.preventDefault();

        const userId = this._userId ? this._userId.value : null;
        const hasUserId = !String.isNullOrWhiteSpace(userId);
        this.setState({ isResetAccepted: false, isUserIdRequired: !hasUserId, isAuthenticating: false, isAuthenticationError: false, isAuthenticationFailure: false });

        if (hasUserId) {
            ServiceClient.post(
                "resets",
                { resetType: "Password", userId: userId },
                () => {},
                () => { this.setState({ isResetAccepted: true }); },
                () => {});
        }
    }

    applyReset(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const password = Page.getInputValue(e, "new-password");
        const isPasswordMismatch = password !== Page.getInputValue(e, "new-password-confirm");

        this.setState({ isPasswordRequest: !isPasswordMismatch, isPasswordRequestError: false, isPasswordRequestMismatch: isPasswordMismatch });

        if (!isPasswordMismatch && password) {
            this.setState({ isPasswordRequest: true });

            if (this._resetToken) {
                ServiceClient.setToken(this._resetToken, this._resetUseSession);
            }

            ServiceClient.post(
                "resets",
                { resetType: "Password", password: password, resetToken: this.getResetToken() },
                () => {},
                () => {
                    if (this._resetToken && this._resetToken.accessToken) {
                        this._resetToken = null;
                        this._resetUseSession = false;
                        this.redirect();
                    } else {
                        this.setState({ isReset: false, isResetComplete: true })
                    }
                },
                () => {
                    ServiceClient.clearToken();
                    this.setState({ isPasswordRequest: false, isPasswordRequestError: true })
                });
        }
    }

    getResetToken() {
        const rt = Page.getUrlVars()["rt"];
        const token = rt ? unescape(rt) : "";
        return typeof token !== 'undefined' && token !== "" ? token : null;
    }

    public render() {
        return (
            <>
                {this.state.isReset &&
                    <div className="about-area space__bottom--r120">
                        <div className="container">
                            <div className="row align-items-center row-25">
                                <div className="col-md-6 order-2 order-md-1">
                                    <div className="about-content">
                                        <div className="section-title space__bottom--25">
                                            <h2 className="section-title__title">Password reset</h2>
                                        </div>
                                        <form onSubmit={this.applyReset}>
                                            <p>Your password should be at least seven characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! &quot; ? $ % ^ & )</p>
                                            <div className="about-content__text space__bottom--40">
                                                <div className="form-floating has-feedback has-feedback-left mb-2">
                                                    <input type="password" className="form-control" id="new-password" required={true} placeholder="Password" />
                                                    <label htmlFor="new-password">Password</label>
                                                    <FontAwesomeIcon className="form-control-feedback" icon={faLock} />
                                                </div>
                                                <div className="form-floating has-feedback has-feedback-left mb-2">
                                                    <input type="password" className="form-control" id="new-password-confirm" required={true} placeholder="Confirm new password" />
                                                    <label htmlFor="new-password-confirm">Confirm new password</label>
                                                    <FontAwesomeIcon className="form-control-feedback" icon={faLock} />
                                                </div>
                                            </div>
                                            {this.state.isPasswordRequestMismatch && <p className="alert alert-danger text-center">The passwords do not match</p>}
                                            {this.state.isPasswordRequestError && <p className="alert alert-danger text-center"><span>The password reset was unsuccessful. Please ensure your password contains the required complexity and is not your previous password.</span></p>}
                                            <button type="submit" disabled={this.state.isPasswordRequest} className="btn btn-default">{this.state.isPasswordRequest && <span><FontAwesomeIcon icon={faSpinner} spin /></span>} Reset my password</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {!this.state.isReset &&
                    <div id="body" className="about-area space__bottom--r120">
                        <div className="container">
                            <div className="row align-items-center row-25">
                                <div className="col-md-6 order-2 order-md-1">
                                    <div className="about-content">
                                        <div className="section-title space__bottom--25">
                                            <h3 className="section-title__sub">Welcome</h3>
                                            <h2 className="section-title__title">Access your documents</h2>
                                        </div>
                                        <form onSubmit={this.login}>
                                            <div className="about-content__text mb-4">
                                                <div className="form-floating has-feedback has-feedback-left mb-2">
                                                    <input className="form-control" name="userId" required placeholder="Username" ref={x => (this._userId = x)} />
                                                    <label htmlFor="userId">Username</label>
                                                    <FontAwesomeIcon className="form-control-feedback" icon={faUser} />
                                                </div>
                                                <div className="form-floating has-feedback has-feedback-left mb-2">
                                                    <input type="password" className="form-control" name="password" required placeholder="Password" />
                                                    <label htmlFor="password">Password</label>
                                                    <FontAwesomeIcon className="form-control-feedback" icon={faLock} />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <span className="link">
                                                    <a href="#" onClick={this.requestReset}><span>Forgot password?</span></a>
                                                </span>
                                                <span className="link">
                                                    <Link to="/login/scan" className="ms-3"><span>Scan badge</span></Link>
                                                </span>
                                            </div>

                                            <p></p>
                                            {this.state.isAuthenticationError && <p className="alert alert-danger">An unexpected error occurred when trying to log you in.</p>}
                                            {this.state.isAuthenticationFailure && <p className="alert alert-danger">Your username and password did not match our records.</p>}
                                            {this.state.isUserIdRequired && <p className="alert alert-danger">Your username is required to reset your password.</p>}
                                            {this.state.isResetAccepted && <p className="alert alert-success">If a matching account was found an email has been sent to allow you to reset your password.</p>}
                                            {this.state.isResetComplete && <p className="alert alert-success">Your password was successfully changed. Please log in again.</p>}
                                            <button id="login_btn" type="submit" disabled={this.state.isAuthenticating} className="btn btn-default">{this.state.isAuthenticating && <span><FontAwesomeIcon icon={faSpinner} spin /></span>} Log in</button>
                                            <label className="customcheck login d-lg-block">
                                                Remember me
                                                <input type="checkbox" name="rememberMe" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}