import { faClipboardUser, faDiagramProject, faPersonChalkboard } from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { useConfiguration } from "@queries/configuration.queries";

export function useMenuOptions() {
  const config = useConfiguration();

  return [
    {
      title: "Certificates",
      to: "/certificates",
      icon: faPersonChalkboard,
      visible: Boolean(config.data?.qualifier),
    },
    {
      title: "Procedures",
      to: "/procedures",
      icon: faFileLines,
      visible: Boolean(config.data?.qualifier),
    },
    {
      title: config.data?.datasheetName ?? "Datasheets",
      to: "/datasheets",
      icon: faDiagramProject,
      visible: Boolean(config.data?.coordinator?.isAep),
    },
    {
      title: "Role Based Interface",
      to: "/rbi",
      icon: faClipboardUser,
      visible: Boolean(config.data?.coordinator),
    },
  ];
}
