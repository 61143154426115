import { Datasheet, Project, SubProject } from "@entities";
import { Dispatch } from "react";
import { PageButton, PageControls } from "./PageControls";
import { ScanDatasheetDialog } from "./Dialogs";
import { ScanDatasheetResponse } from "@entities/ScanDatasheetResponse";
import { String } from "@utils";
import { useConfiguration } from "@queries/configuration.queries";
import { useGetDatasheetsBySubProject, useScanDatasheet } from "@queries/datasheet.queries";
import { useGetProjectList, useGetSubProjectsByProject } from "@queries/project.queries";
import { useModal } from "hooks";

import styles from "./DatasheetSelection.module.scss";

type DatasheetSelectionProps = {
  project?: Project;
  subProject?: SubProject;
  datasheet?: Datasheet;
  updateState: Dispatch<{
    type: "Project" | "SubProject" | "Datasheet";
    payload?: Project | SubProject | Datasheet;
  }>;
};

export function DatasheetSelection(props: DatasheetSelectionProps) {
  const modal = useModal();
  const config = useConfiguration();
  const scan = useScanDatasheet();
  const projects = useGetProjectList();
  const subProjects = useGetSubProjectsByProject(props.project?.id);
  const datasheets = useGetDatasheetsBySubProject(props.subProject?.id);

  const allowNext = Boolean(config.data?.coordinator?.isParalloy ? props.subProject : props.datasheet);

  function handleScanClicked() {
    modal.show(<ScanDatasheetDialog onScan={scanDatasheet} />);
  }

  function scanDatasheet(datasheetId: string) {
    scan.mutate(datasheetId, {
      onSuccess: (response: ScanDatasheetResponse) => {
        props.updateState({ type: "Project", payload: response.project });
        props.updateState({ type: "SubProject", payload: response.subProject });
        props.updateState({ type: "Datasheet", payload: response.datasheet });
      },
    });
  }

  return (
    <div className={styles.container}>
      <div className="row mt-2">
        <div className="col-xs-auto col-md-3">
          <label htmlFor="project">{config.data?.projectName ?? "Project"}</label>
        </div>
        <div className="col-xs-auto col-md-9">
          <select
            name="project"
            className="form-select"
            value={props.project?.id ?? String.Empty}
            disabled={projects.isLoading}
            onChange={(e) =>
              props.updateState({
                type: "Project",
                payload: projects.data?.find((x) => x.id === e.currentTarget.value),
              })
            }
          >
            <option />
            {projects.isSuccess &&
              projects.data.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-xs-auto col-md-3">
          <label htmlFor="project">{config.data?.subProjectName ?? "Sub-Project"}</label>
        </div>
        <div className="col-xs-auto col-md-9">
          <select
            name="subproject"
            className="form-select"
            value={props.subProject?.id ?? String.Empty}
            disabled={!props.project || subProjects.isLoading}
            onChange={(e) =>
              props.updateState({
                type: "SubProject",
                payload: subProjects.data?.find((x) => x.id === e.currentTarget.value),
              })
            }
          >
            <option />
            {subProjects.isSuccess &&
              subProjects.data.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-xs-auto col-md-3">
          <label htmlFor="project">{config.data?.datasheetName ?? "Datasheet"}</label>
        </div>
        <div className="col-xs-auto col-md-9">
          <select
            name="datasheet"
            className="form-select"
            value={props.datasheet?.datasheetId ?? String.Empty}
            disabled={!props.subProject || datasheets.isLoading}
            onChange={(e) =>
              props.updateState({
                type: "Datasheet",
                payload: datasheets.data?.find((x) => x.datasheetId === e.currentTarget.value),
              })
            }
          >
            <option />
            {datasheets.isSuccess &&
              datasheets.data.map((x) => (
                <option key={x.datasheetId} value={x.datasheetId}>
                  {x.datasheetName}
                </option>
              ))}
          </select>
        </div>
      </div>

      <PageControls showNext allowNext={allowNext}>
        <PageButton onClick={handleScanClicked}>Scan</PageButton>
      </PageControls>
    </div>
  );
}
