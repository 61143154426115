import { Navigate } from "react-router";
import { useCoordinatorUser } from "@queries/user.queries";
import { useConfiguration } from "@queries/configuration.queries";

export const RoleSelector = () => {
  const user = useCoordinatorUser();
  const config = useConfiguration();

  if (user.isLoading || config.isLoading) {
    return <p>Loading...</p>;
  }

  if (config.data?.coordinator?.isParalloy) {
    if (config.data?.coordinator.isInspector) {
      return <Navigate to="paralloy/inspector" />;
    } else {
      return <Navigate to="paralloy/welder" />;
    }
  }

  if (config.data?.coordinator?.isHitachi) {
    if (config.data?.coordinator.isInspector) {
      return <Navigate to="hitachi/inspector" />;
    } else {
      return <Navigate to="hitachi/welder" />;
    }
  }

  if (user.data?.inspector) {
    return <Navigate to="inspector" />;
  } else {
    return <Navigate to="welder" />;
  }
};
