import { ButtonHTMLAttributes, ReactElement } from "react";
import { SpinButton, SpinButtonProps } from "@components/form/SpinButton";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

type PageControlProps = {
  showBack?: boolean;
  showNext?: boolean;
  allowBack?: boolean;
  allowNext?: boolean;
  children?:
    | ReactElement<ButtonHTMLAttributes<HTMLButtonElement>>
    | ReactElement<ButtonHTMLAttributes<HTMLButtonElement>>[];
};

export const PageButton = (props: SpinButtonProps) => (
  <SpinButton className="btn btn-default" {...props}>
    {props.children}
  </SpinButton>
);

export const PageControls = (props: PageControlProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const currentPage = Number(searchParams.get("page")) || 1;

  return (
    <div className="float-end">
      <div className="btn-toolbar mt-4 mb-5 gap-1">
        {props.showBack && (
          <PageButton onClick={() => navigate(-1)} disabled={props.allowBack === false}>
            Back
          </PageButton>
        )}
        {props.children}
        {props.showNext && (
          <PageButton
            onClick={() => setSearchParams({ page: String(currentPage + 1) })}
            disabled={props.allowNext === false}
          >
            Continue
          </PageButton>
        )}
      </div>
    </div>
  );
};
