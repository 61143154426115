import { PageButton, PageControls } from "../../../common/PageControls";
import { SaveChangesDialog, WelderSelectionDialog } from "./Dialogs";
import { useModal } from "hooks";
import { useSave } from "../queries";
import { useState } from "react";
import { Weld } from "../types";

export function MainInterface(props: { welds: Weld[] }) {
  const [welds, setWelds] = useState(props.welds);

  const save = useSave();
  const modal = useModal();

  function saveChanges() {
    modal.show(<SaveChangesDialog onAccept={() => save.mutate(welds)} />);
  }

  function updateRow(weldId: string, field: string, value: unknown) {
    setWelds((prev) =>
      prev.map((weld) => {
        if (weld.weldId === weldId) {
          return { ...weld, [field]: value };
        }
        return weld;
      })
    );
  }

  function setWeldStatus(weld: Weld, status: string) {
    updateRow(weld.weldId, "status", status);

    if (status === "Made" && weld.welders.length > 1) {
      modal.show(
        <WelderSelectionDialog
          welders={weld.welders}
          onAccept={(welders) => updateRow(weld.weldId, "welders", welders)}
        />
      );
    }
  }

  return (
    <small>
      <div className="table-responsive">
        <table className="table table-hover table-sm text-center text-nowrap align-middle">
          <thead>
            <tr>
              <th>Weld</th>
              <th>Datasheet</th>
              <th>Joint Type</th>
              <th>Item</th>
              <th>Melt No.</th>
              <th>Unique No.</th>
              <th>Item</th>
              <th>Melt No.</th>
              <th>Unique No.</th>
              <th>WPS</th>
              <th>Filler</th>
              <th>Batch No.</th>
              <th>Region</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {welds.map((row, index) => (
              <tr key={index}>
                <th>{row.weldNumber}</th>
                <td>{row.datasheet}</td>
                <td>{row.jointType}</td>
                <td>{row.component1}</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={row.meltNumberA}
                    onChange={(e) => updateRow(row.weldId, "meltNumberA", e.currentTarget.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={row.uniqueIdA}
                    onChange={(e) => updateRow(row.weldId, "uniqueIdA", e.currentTarget.value)}
                  />
                </td>
                <td>{row.component2}</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={row.meltNumberB}
                    onChange={(e) => updateRow(row.weldId, "meltNumberB", e.currentTarget.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={row.uniqueIdB}
                    onChange={(e) => updateRow(row.weldId, "uniqueIdB", e.currentTarget.value)}
                  />
                </td>
                <td>{row.wpsNumber}</td>
                <td>{row.fillerType}</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={row.fillerBatch}
                    onChange={(e) => updateRow(row.weldId, "fillerBatch", e.currentTarget.value)}
                  />
                </td>
                <td>
                  <select
                    className="form-select"
                    value={row.region}
                    onChange={(e) => updateRow(row.weldId, "region", e.currentTarget.value)}
                  >
                    <option></option>
                    <option>Root/hot pass</option>
                    <option>Root and fill</option>
                    <option>Fill</option>
                    <option>Fill and Cap</option>
                    <option>Cap</option>
                    <option>All</option>
                  </select>
                </td>
                <td>
                  <select
                    className="form-select"
                    value={row.status}
                    disabled={!row.region}
                    onChange={(e) => setWeldStatus(row, e.currentTarget.value)}
                  >
                    <option></option>
                    <option>Unfinished</option>
                    <option>Made</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PageControls>
        <PageButton onClick={saveChanges} disabled={save.isLoading} loading={save.isLoading}>
          Save and Finish
        </PageButton>
      </PageControls>
    </small>
  );
}
