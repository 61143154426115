export function Footer(props: { version?: string }) {
  return (
    <div id="footer" className="copyright-area background-color--deep-dark space__inner--y30">
      <div className="container">
        <div className="row copyright-text">
          <span className="copyright-text">
            <a href="https://www.twisoftware.com" title="TWI Software" className="copyright-text">
              &copy; {new Date().getFullYear()} TWI Software, All rights reserved
            </a>
          </span>
        </div>
        <span className="version">{props.version}</span>
      </div>
    </div>
  );
}
