import * as api from "api/menus";
import { useQuery } from "react-query";

export function useCoordinatorMenu(menu: string) {
  return useQuery(["menus", menu], () => api.getCoordinatorMenu(menu), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
}

export function useWpsList(process: string) {
  return useQuery(["menus", "wpslist", process], () => api.getWpsList(process), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
}

export function useFillerClassifications(process: string, wps: string) {
  return useQuery(["menus", "fillers", process, wps], () => api.getFillerClassifications(process, wps), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
}
