import * as api from "api/configurations";
import { ServiceClient } from "@utils";
import { useQuery } from "react-query";

export function useConfiguration() {
  const isLoggedIn = ServiceClient.isAuthenticated();
  return useQuery("configurations", api.getConfiguration, {
    enabled: isLoggedIn,
  });
}
