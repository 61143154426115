import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FilterPanel } from "./components/FilterPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchResults } from "./components/SearchResults";
import { useState } from "react";

export function Certificates() {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  function toggleSearchExpanded(e: React.MouseEvent) {
    e.preventDefault();
    setIsSearchExpanded(!isSearchExpanded);
  }

  return (
    <>
      <div className="nav-column">
        <div className="document-search">
          <h5>Search criteria</h5>
          {!isSearchExpanded && (
            <a href="#" role="button" onClick={toggleSearchExpanded} className="btn btn-default search-expander">
              View search criteria <FontAwesomeIcon icon={faChevronDown} />
            </a>
          )}
          {isSearchExpanded && (
            <a href="#" role="button" onClick={toggleSearchExpanded} className="btn btn-default search-expander">
              Hide search criteria <FontAwesomeIcon icon={faChevronUp} />
            </a>
          )}
          <hr />
          <div className={isSearchExpanded ? "" : "collapsed"}>
            <FilterPanel />
          </div>
        </div>
      </div>
      <div className="main-column">
        <div className="search-results">
          <h2>Certificates</h2>
          <SearchResults />
        </div>
      </div>
    </>
  );
}
