import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpinButton } from "@components/form/SpinButton";
import { useEffect, useRef, useState } from "react";
import { useLogin } from "@queries/auth.queries";

export function ScanBadge() {
  const barcodeInput = useRef<HTMLInputElement>(null);
  const [barcode, setBarcode] = useState<string>("");
  const login = useLogin(true);

  useEffect(() => {
    if (barcodeInput.current) {
      barcodeInput.current.focus();
    }
  }, []);

  function handleLogin(e: React.FormEvent) {
    e.preventDefault();
    login.mutate({ grantType: "password", barcode });
  }

  return (
    <div id="body" className="about-area space__bottom--r120">
      <div className="container">
        <div className="row align-items-center row-25">
          <div className="col-md-6 order-2 order-md-1">
            <div className="about-content">
              <div className="section-title space__bottom--25">
                <h2 className="section-title__title">Scan your badge</h2>
              </div>
              <form onSubmit={handleLogin}>
                <div className="about-content__text space__bottom--40">
                  <div className="form-floating has-feedback has-feedback-left mb-2">
                    <input
                      className="form-control"
                      name="barcode"
                      value={barcode}
                      onChange={(e) => setBarcode(e.currentTarget.value)}
                      ref={barcodeInput}
                      required
                    />
                    <label htmlFor="barcode">Barcode/QR Code</label>
                    <FontAwesomeIcon className="form-control-feedback" icon={faUser} />
                  </div>
                </div>
                {login.isError && <p className="alert alert-danger">User not found, please re-scan.</p>}
                <SpinButton id="login_btn" type="submit" className="btn btn-default" loading={login.isLoading}>
                  Log in
                </SpinButton>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
