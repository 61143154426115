import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Portal } from "@components";
import { ServiceClient } from "@utils";
import { useLostFocus, useMenuOptions } from "hooks";

import styles from "./HamburgerMenu.module.scss";

export function HamburgerMenu(props: HTMLAttributes<HTMLDivElement>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const options = useMenuOptions();

  // Close on lost focus.
  const menuRef = useRef(null);
  useLostFocus(menuRef, () => setIsOpen(false));

  // Close on link clicked.
  const location = useLocation();
  useEffect(() => setIsOpen(false), [location]);

  return (
    <>
      <FontAwesomeIcon icon={faBars} role="button" onClick={() => setIsOpen(true)} />

      <Portal target="portal-root">
        <nav ref={menuRef} className={isOpen ? styles.visible : styles.hidden} {...props}>
          <FontAwesomeIcon role="button" icon={faClose} className="float-end" onClick={() => setIsOpen(false)} />

          <h4 className={styles.header}>Menu</h4>
          <ul className={styles.links}>
            {options
              .filter((x) => x.visible)
              .map((props, index) => (
                <li key={index}>
                  <Link to={props.to}>{props.title}</Link>
                </li>
              ))}
            <li>
              <hr />
              <Link to="/settings">Settings</Link>
            </li>
            <li>
              <a href="#" onClick={() => ServiceClient.logout()}>
                Log out
              </a>
            </li>
          </ul>
        </nav>
      </Portal>
    </>
  );
}
