import { DatasheetState } from "./DatasheetState";

export class DatasheetsState {
    loadingDatasheet: boolean = false;
    loadingDatasheets: boolean = false;
    datasheetCaption: string = "";
    datasheetsCaption: string = "";
    datasheets: any;
    selectedDatasheet: any;
    holdPoints: any[] = [];
    attachments: any[] = [];
    allowFinalSignoff: boolean = false;
    showPrompt: boolean = false;
    confirmSignoff: boolean = false;
    confirmComplete: boolean = false;

    selectedNode: DatasheetState = new DatasheetState();
}