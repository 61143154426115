import { useState } from "react";
import { faEraser, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router-dom";
import { ProcedureFilters } from "../types/ProcedureFilters";

const initialState: ProcedureFilters = {
  standard: "",
  recordType: "",
  recordNumber: "",
};

export function FilterPanel() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<ProcedureFilters>({
    standard: searchParams.get("standard") || initialState.standard,
    recordType: searchParams.get("recordType") || initialState.recordType,
    recordNumber: searchParams.get("recordNumber") || initialState.recordNumber,
  });

  function handleReset() {
    setFilters(initialState);
    setSearchParams();
  }

  function handleSearch(e: React.FormEvent) {
    e.preventDefault();
    setSearchParams(new URLSearchParams(filters));
  }

  function handleFilterChanged(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const fieldName = e.currentTarget.id;
    const newValue = e.currentTarget.value;

    setFilters((prev) => ({ ...prev, [fieldName]: newValue }));
  }

  return (
    <div>
      <form onSubmit={handleSearch}>
        <button className="btn btn-default search" title="Apply filter">
          Apply filter <FontAwesomeIcon icon={faFilter} />
        </button>
        <button type="button" onClick={handleReset} className="btn btn-light clear" title="Clear filter">
          Clear <FontAwesomeIcon icon={faEraser} />
        </button>
        <hr />

        <div className="mb-2">
          <label htmlFor="standard" className="form-label">
            Record standard
          </label>
          <select id="standard" className="form-select" value={filters.standard} onChange={handleFilterChanged}>
            <option />
            <option>API</option>
            <option>ASME</option>
            <option>AWS</option>
            <option>ISO</option>
          </select>
        </div>
        <div className="mb-2">
          <label htmlFor="recordType" className="form-label">
            Record type
          </label>
          <select id="recordType" className="form-select" value={filters.recordType} onChange={handleFilterChanged}>
            <option />
            <option>WPS</option>
            <option>PQR</option>
          </select>
        </div>
        <div className="mb-2">
          <label htmlFor="recordNumber" className="form-label">
            Record number
          </label>
          <input
            className="form-control"
            id="recordNumber"
            value={filters.recordNumber}
            onChange={handleFilterChanged}
          />
        </div>
      </form>
    </div>
  );
}
