import * as api from "api/project";
import { useQuery } from "react-query";

export function useGetProjectList() {
  const queryKey = ["projects"];
  return useQuery(queryKey, api.getProjectList);
}

export function useGetSubProjectsByProject(projectId?: string) {
  const queryKey = ["subprojects", projectId];
  return useQuery(queryKey, () => api.getSubProjectsByProject(projectId), {
    enabled: Boolean(projectId),
  });
}
