import * as React from "react";
import { DatasheetState } from "./types/DatasheetState";
import { DatasheetsState } from "./types/DatasheetsState";
import NavigationTree from "./components/NavigationTree";
import { DateTime, ServiceClient } from "@utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner, faRotate, faSignature } from '@fortawesome/free-solid-svg-icons';
import { faFile, faFilePdf, faFileWord, faFileExcel, faFileImage, faFileVideo, faFileAudio, faFileArchive, faFileCode, faFileAlt } from '@fortawesome/free-regular-svg-icons';

export class Datasheets extends React.PureComponent<{}, DatasheetsState> {
    _navigationTree: NavigationTree|null = null;

    constructor(props: {}, context: any) {
        super(props, context);
        this.state = new DatasheetsState();

        this.buildNavigation = this.buildNavigation.bind(this);
        this.selectedNodeChanged = this.selectedNodeChanged.bind(this);
        this.getAttachment = this.getAttachment.bind(this);
        this.promptSignoff = this.promptSignoff.bind(this);
        this.completeSignoff = this.completeSignoff.bind(this);
        this.clearSignoff = this.clearSignoff.bind(this);
    }

    componentDidMount() {
        this.buildNavigation(null);
    }

    buildNavigation(e: any) {
        if (e) {
            e.preventDefault();
        }

        this.setState({ loadingDatasheets: true, selectedDatasheet: null, selectedNode: new DatasheetState(), datasheetsCaption: "Loading, please wait...", datasheets: [] });

        ServiceClient.get(
            "aep/datasheets",
            () => { this.setState({ loadingDatasheets: false }) },
            (data: any) => {
                this.setState({ datasheetsCaption: data.length > 0 ? "Please select a traveler." : "No travelers assigned to you.", datasheets: data })
            },
            (error: Response) => {
                this.setState({ datasheetsCaption: "Failed to load travelers." });
            });
    }

    selectedNodeChanged(e: any) {
        const selectedNode = new DatasheetState();
        selectedNode.name = e.name;
        selectedNode.subName = e.subName;
        selectedNode.breadcrumb = e.breadcrumb;

        this.setState({ selectedNode: selectedNode });
        this.getDatasheet(e.id);
    }

    getAttachment(e: any, attachmentId: string) {
        e.preventDefault();
        ServiceClient.redirectWithAuth("datasheets/" + this.state.selectedDatasheet.datasheetId + "/attachments/" + attachmentId);
    }

    getWmlReport(e: any, welder: any) {
        e.preventDefault();

        let parameters = "";

        if (welder.welderName) {
            parameters += "welderName=" + welder.welderName;
        }

        if (welder.welderStampNumber) {
            if (welder.welderName) {
                parameters += "&";
            }

            parameters += "stampNumber=" + welder.welderStampNumber;
        }

        ServiceClient.redirectWithAuth("reports/WmlReport", parameters);
    }

    getWpsReport(e: any, wpsNumber: string) {
        e.preventDefault();
        ServiceClient.redirectWithAuth("reports/WpsReport", "wpsNumber=" + wpsNumber);
    }

    getFileList(data: any, remainder: number) {
        const result: any[] = [];

        for (let i = 0; i < data.length; i++) {
            if (i % 2 === remainder) {
                result.push(data[i]);
            }
        }

        return result;
    }

    getDatasheet = (id: string) => {
        if (!id) {
            return;
        }

        this.setState({ loadingDatasheet: true, datasheetCaption: "", selectedDatasheet: null })

        ServiceClient.get(
            "aep/datasheets/" + id,
            () => { this.setState({ loadingDatasheet: false }) },
            (data: any) => {
                for (const holdPoint of data.holdPoints) {
                    holdPoint.isDirty = false;
                }

                this.setState({
                    selectedDatasheet: data,
                    holdPoints: data.holdPoints,
                    attachments: data.attachments,
                    allowFinalSignoff: this.getAllowFinalSignoff(data.holdPoints, data.attachments)
                });
            },
            (error: Response) => {
                this.setState({ datasheetCaption: "An error occured when loading the datasheet." });
            });
    };

    allowAttachmentSign = (tag: string) => {
        const regex = /^[A-Z]-[0-9]$/g;
        return tag !== null && tag.match(regex) !== null;
    }

    getAllowFinalSignoff = (holdPoints: any, attachments: any) => {
        if (holdPoints && this._navigationTree && this._navigationTree._selectedNode) {
            let isComplete = true;
            for (const hp of holdPoints) {
                if ((hp.isRequired && !hp.release) || hp.isDirty) {
                    isComplete = false;
                    break;
                }
            }

            this._navigationTree._selectedNode.setState({ isFinalSignOff: isComplete });
        }

        const ds = this.state.selectedDatasheet;
        let allowSignoff = ds && ds.signOff1Date ? true : false;

        if (allowSignoff) {
            for (const attachment of attachments) {
                if (!attachment.isSigned && this.allowAttachmentSign(attachment.description)) {
                    allowSignoff = false;
                    break;
                }
            }
        }

        return allowSignoff;
    }

    getElectrodes = (electrodes: any[], emptyResult: string | null) => {
        if (!electrodes) {
            return emptyResult;
        }

        const result = [];
        for (const electrode of electrodes) {
            result.push(electrode.electrodeName);
        }

        return result.length > 0 ? result.join(", ") : emptyResult;
    }

    getWelder = (welder: any) => {
        let result = "";

        if (welder.welderName && welder.welderStampNumber) {
            result = welder.welderName + " (" + welder.welderStampNumber + ")";
        } else if (welder.welderName) {
            result = welder.welderName;
        } else if (welder.welderStampNumber) {
            result = welder.welderStampNumber;
        }

        return result;
    }

    allowHoldPointSign = (holdpoint: any) => {
        const ds = this.state.selectedDatasheet;
        let result = holdpoint.isRequired && (holdpoint.release === null || holdpoint.release.match(/^ *$/) !== null);

        if (holdpoint.holdPoint === "A. WPS Specifics") { // 3a
            for (const plan of ds.plans) {
                result = result && plan.jointDesign && plan.wpsNumber && this.getElectrodes(plan.electrodes, null) && plan.weldSize && plan.preheatTemperature;
                if (!result) {
                    break;
                }
            }
        } else if (holdpoint.holdPoint === "B. PWHT Calcs") { // 3b
            for (const plan of ds.plans) {
                result = result && plan.temperatureRange && plan.holdTimeMin && plan.heatRateMax && plan.coolRateMax;
                if (!result) {
                    break;
                }
            }
        } else if (holdpoint.holdPoint === "C. " + ds.ndeMethodTitle) { // 3c
            for (const plan of ds.plans) {
                result = result && plan.ndeTechnician && plan.ndeExpiryDate;
                if (!result) {
                    break;
                }
            }
        } else if (holdpoint.holdPoint === "A. Preheat") { // 4a
            for (const plan of ds.plans) {
                result = result && plan.preheatTemperature;
                if (!result) {
                    break;
                }
            }
        }

        return result;
    }

    saveHoldPoint = (e: any, value: any) => {
        e.preventDefault();

        // Recurse up the list to find first entry with a category
        let topHoldPointIndex = 0;
        const hps = this.state.holdPoints.map((x) => x);

        for (let i = 0; i < hps.length; i++) {
            const holdPoint = hps[i];
            if (holdPoint.category) {
                topHoldPointIndex = i;
            }

            if (holdPoint.holdPointId === value.holdPointId) {
                let lastHoldPointIndex = topHoldPointIndex

                for (let j = topHoldPointIndex; j < hps.length; j++) {
                    const currentHoldPoint = hps[j];

                    if (j !== topHoldPointIndex && currentHoldPoint.category) {
                        lastHoldPointIndex = j;
                        break;
                    }

                    if (!currentHoldPoint.isDirty) {
                        continue;
                    }

                    ServiceClient.post(
                        "aep/datasheets/" + this.state.selectedDatasheet.datasheetId + "/holdpoints/",
                        currentHoldPoint,
                        () => {},
                        (content: any) => {
                            this.setState({ allowFinalSignoff: this.getAllowFinalSignoff(this.state.holdPoints, this.state.attachments) })
                        },
                        () => {});
                }

                for (let l = topHoldPointIndex; l < hps.length; l++) {
                    if (l === lastHoldPointIndex) {
                        break;
                    }

                    hps[l].isDirty = false;
                }

                this.setState({ holdPoints: hps.map((x) => x) })

                break;
            }
        }
    }

    resetHoldPoint = (e: any, value: any) => {
        e.preventDefault();

        // Recurse up the list to find first entry with a category
        let topHoldPointIndex = 0;
        const hps = this.state.holdPoints.map((x) => x);

        for (let i = 0; i < hps.length; i++) {
            const holdPoint = hps[i];
            if (holdPoint.category) {
                topHoldPointIndex = i;
            }

            if (holdPoint.holdPointId === value.holdPointId) {
                for (let j = topHoldPointIndex; j < hps.length; j++) {
                    const currentHoldPoint = hps[j];

                    if (j !== topHoldPointIndex && currentHoldPoint.category) {
                        break;
                    }

                    if (!currentHoldPoint.isDirty) {
                        continue;
                    }

                    ServiceClient.get(
                        "aep/datasheets/" + this.state.selectedDatasheet.datasheetId + "/holdpoints/" + currentHoldPoint.holdPointId,
                        () => {},
                        (content: any) => {
                            if (content) {
                                for (let k = topHoldPointIndex; k < hps.length; k++) {
                                    const hp = hps[k];
                                    if (hp.holdPointId === content.holdPointId) {
                                        hp.release = content.release;
                                        hp.isDirty = false;

                                        this.setState({ holdPoints: hps.map((x) => x) });
                                        break;
                                    }
                                }

                                this.setState({ holdPoints: hps });
                            }
                        },
                        () => {});
                }

                break;
            }
        }
    }

    signHoldPoint = (e: any, holdPoint: any) => {
        e.preventDefault();

        const hps = this.state.holdPoints.map((x) => x);
        let topHoldPoint = null;

        for (const hp of hps) {
            if (hp.category) {
                topHoldPoint = hp;
            }

            if (hp.holdPointId === holdPoint.holdPointId) {
                hp.isDirty = true;
                hp.release = this.state.selectedDatasheet.inspectorInitials + " - " + DateTime.getLocalDate(new Date())
                topHoldPoint.isDirty = true;
                break;
            }
        }

        this.setState({ holdPoints: hps });
    }

    signAttachment = (e: any, attachment: any) => {
        e.preventDefault();

        ServiceClient.get(
            "aep/signings/" + attachment.attachmentId,
            () => {},
            (content: any) => {
                const attachments = this.state.attachments.map((x) => x)
                for (const attach of attachments) {
                    if (attach.attachmentId === attachment.attachmentId) {
                        attach.isSigned = true;
                        break;
                    }
                }

                this.setState({ attachments: attachments, allowFinalSignoff: this.getAllowFinalSignoff(this.state.holdPoints, attachments) });
            },
            () => {});
    };

    promptSignoff = (e: any) => {
        e.preventDefault();

        this.setState({ showPrompt: true, confirmSignoff: true });
    }

    clearSignoff = () => {
        this.setState({ confirmSignoff: false, confirmComplete: false, showPrompt: false });
    }

    clearSignoffAndRebuild = () => {
        this.setState({ selectedDatasheet: null, selectedNode: new DatasheetState() });
        this.clearSignoff();
        this.buildNavigation(null);
    }

    completeSignoff = () => {
        ServiceClient.post(
            "datasheets/" + this.state.selectedDatasheet.datasheetId + "/signatures",
            { datasheetId: this.state.selectedDatasheet.datasheetId },
            () => {},
            (content: any) => {
                this.setState({ showPrompt: true, confirmSignoff: false, confirmComplete: true});
            },
            () => {}
        );
    }

    public render() {
        return (
            <div id="body" className="main-row datasheets">
                {this.state.showPrompt &&
                    <div id="opaque"></div>
                }
                {this.state.confirmSignoff &&
                    <div id="confirm-dialog">
                        <div className="dialog-signoff">
                            Are you sure you want to complete signoff?
                            <div>
                                <button className="btn btn-default" onClick={this.completeSignoff}>Yes</button>
                                <span> </span>
                                <button className="btn btn-secondary" onClick={this.clearSignoff}>No</button>
                            </div>
                        </div>
                    </div>
                }
                {this.state.confirmComplete &&
                    <div id="complete-dialog">
                        <div className="dialog-signoff">
                            Signoff complete.
                            <div>
                                <button className="btn btn-default" onClick={this.clearSignoffAndRebuild}>OK</button>
                            </div>
                        </div>
                    </div>
                }

                <div className="nav-column">
                    <span className="nav-heading">TRAVELERS</span>
                    {!this.state.loadingDatasheets && <a href="#" onClick={this.buildNavigation}><FontAwesomeIcon className="link" icon={faRotate} /></a>}
                    {this.state.loadingDatasheets && <span><FontAwesomeIcon icon={faRotate} spin /></span>}
                    {<span className="caption">{this.state.datasheetsCaption}</span>}
                    <hr />
                    <NavigationTree data={this.state.datasheets} selectedItemChanged={this.selectedNodeChanged} ref={x => (this._navigationTree = x)} />
                </div>
                <div className="main-column">
                    <span>{this.state.selectedNode.breadcrumb}</span>
                    <span> </span>
                    {this.state.loadingDatasheet && <span><FontAwesomeIcon icon={faSpinner} spin /></span>}
                    <span className="sub-name">{this.state.selectedNode.subName}</span>
                    {this.state.selectedNode.subName && <hr />}

                    <div className="datasheet-caption">
                        <span>{this.state.datasheetCaption}</span>
                    </div>

                    {this.state.selectedDatasheet && (
                        <div>
                            <div className="sidebar">
                                <h3 className="sidebar-title">Header</h3>
                                <div className="grid-container">
                                    <div className="grid-child">
                                        <ul>
                                            <li><label>R or S Cert. Mark</label><span>{this.state.selectedDatasheet.certMark}</span></li>
                                            <li><label>Plant</label><span>{this.state.selectedDatasheet.plant}</span></li>
                                            <li><label>Unit</label><span>{this.state.selectedDatasheet.unit}</span></li>
                                            <li><label>State</label><span>{this.state.selectedDatasheet.state}</span></li>
                                            <li><label>Job Number</label><span>{this.state.selectedDatasheet.jobNumber}</span></li>
                                            <li><label>Date</label><span>{DateTime.getLocalDate(this.state.selectedDatasheet.date)}</span></li>
                                            <li><label>Traveler Number</label><span>{this.state.selectedDatasheet.travelerNumber}</span></li>
                                        </ul>
                                    </div>
                                    <div className="grid-child">
                                        <ul>
                                            <li><label>&nbsp;</label></li>
                                            <li><label>Inspector Phone Approval</label><span>{this.state.selectedDatasheet.inspectorPhoneApproval}</span></li>
                                            <li><label>Quality Level</label><span>{this.state.selectedDatasheet.qualityLevel}</span></li>
                                            <li><label>Applicable Code</label><span>{this.state.selectedDatasheet.applicableCode}</span></li>
                                            <li><label>Design Temperature (&#176;F)</label><span>{this.state.selectedDatasheet.designTemperature}</span></li>
                                            <li><label>Pressure (psig)</label><span>{this.state.selectedDatasheet.pressure}</span></li>
                                            <li><label>Repair Type</label><span>{this.state.selectedDatasheet.repairType}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="sidebar">
                                <h3 className="sidebar-title">Repair Description and Location</h3>
                                <span>{this.state.selectedDatasheet.repairDescription}</span>
                            </div>

                            <div className="sidebar">
                                <h3 className="sidebar-title">1. Drawings, Specs, etc.</h3>
                                {this.state.selectedDatasheet.drawings.length === 0 && <span>None</span>}
                                <div className="grid-container">
                                    <div className="grid-child">
                                        <ul>
                                            {this.getFileList(this.state.selectedDatasheet.drawings, 0).map((drawing: { attachmentId: string, fileName: string, fileType: number }, index: number) =>
                                                <li key={index} className="attachment">
                                                    {drawing.fileType === 0 && <FontAwesomeIcon icon={faFile} />}
                                                    {drawing.fileType === 1 && <FontAwesomeIcon icon={faFilePdf} />}
                                                    {drawing.fileType === 2 && <FontAwesomeIcon icon={faFileWord} />}
                                                    {drawing.fileType === 3 && <FontAwesomeIcon icon={faFileExcel} />}
                                                    {drawing.fileType === 4 && <FontAwesomeIcon icon={faFileImage} />}
                                                    {drawing.fileType === 5 && <FontAwesomeIcon icon={faFileVideo} />}
                                                    {drawing.fileType === 6 && <FontAwesomeIcon icon={faFileAudio} />}
                                                    {drawing.fileType === 7 && <FontAwesomeIcon icon={faFileArchive} />}
                                                    {drawing.fileType === 8 && <FontAwesomeIcon icon={faFileCode} />}
                                                    {drawing.fileType === 9 && <FontAwesomeIcon icon={faFileAlt} />}
                                                    <span> </span>
                                                    <a href="#" onClick={e => this.getAttachment(e, drawing.attachmentId)} title="Click to View">{drawing.fileName}</a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="grid-child">
                                        <ul>
                                            {this.getFileList(this.state.selectedDatasheet.drawings, 1).map((drawing: { attachmentId: string, fileName: string, fileType: number }, index: number) =>
                                                <li key={index} className="attachment">
                                                    {drawing.fileType === 0 && <FontAwesomeIcon icon={faFile} />}
                                                    {drawing.fileType === 1 && <FontAwesomeIcon icon={faFilePdf} />}
                                                    {drawing.fileType === 2 && <FontAwesomeIcon icon={faFileWord} />}
                                                    {drawing.fileType === 3 && <FontAwesomeIcon icon={faFileExcel} />}
                                                    {drawing.fileType === 4 && <FontAwesomeIcon icon={faFileImage} />}
                                                    {drawing.fileType === 5 && <FontAwesomeIcon icon={faFileVideo} />}
                                                    {drawing.fileType === 6 && <FontAwesomeIcon icon={faFileAudio} />}
                                                    {drawing.fileType === 7 && <FontAwesomeIcon icon={faFileArchive} />}
                                                    {drawing.fileType === 8 && <FontAwesomeIcon icon={faFileCode} />}
                                                    {drawing.fileType === 9 && <FontAwesomeIcon icon={faFileAlt} />}
                                                    <span> </span>
                                                    <a href="#" onClick={e => this.getAttachment(e, drawing.attachmentId)} title="Click to View">{drawing.fileName}</a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="sidebar">
                                <h3 className="sidebar-title">2A. Original Material</h3>
                                <table className="table striped">
                                    <thead>
                                        <tr>
                                            <th>P No.</th>
                                            <th>G No.</th>
                                            <th>Size (in.)</th>
                                            <th>Schedule</th>
                                            <th>Thickness (in.)</th>
                                            <th>Diameter (in.)</th>
                                            <th>PO/Heat Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.selectedDatasheet.originalMaterials.map((material: any, index: number) =>
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td colSpan={6}><span>{material.rowIdentifier}</span>. <span>{material.materialForm}</span> <span>{material.materialSpecification}</span></td>
                                                </tr>
                                                <tr>
                                                    <td>{material.materialPNumber}</td>
                                                    <td>{material.materialGNumber}</td>
                                                    <td>{material.size}</td>
                                                    <td>{material.schedule}</td>
                                                    <td>{material.thickness}</td>
                                                    <td>{material.diameter}</td>
                                                    <td>{material.poNumber}</td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="sidebar">
                                <h3 className="sidebar-title">2B. Replacement Material</h3>
                                <table className="table striped">
                                    <thead>
                                        <tr>
                                            <th>P No.</th>
                                            <th>G No.</th>
                                            <th>Size (in.)</th>
                                            <th>Schedule</th>
                                            <th>Thickness (in.)</th>
                                            <th>Diameter (in.)</th>
                                            <th>PO/Heat Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.selectedDatasheet.replacementMaterials.map((material: any, index: number) =>
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td colSpan={6}><span>{material.rowIdentifier}</span>. <span>{material.materialForm}</span> <span>{material.materialSpecification}</span></td>
                                                </tr>
                                                <tr>
                                                    <td>{material.materialPNumber}</td>
                                                    <td>{material.materialGNumber}</td>
                                                    <td>{material.size}</td>
                                                    <td>{material.schedule}</td>
                                                    <td>{material.thickness}</td>
                                                    <td>{material.diameter}</td>
                                                    <td>{material.poNumber}</td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="sidebar">
                                <h3 className="sidebar-title">3. Planning</h3>
                                {this.state.selectedDatasheet.plans.map((plan: any, index:number) =>
                                    <div key={index}>
                                        <h5 className="header-footer">
                                            <span className="title">{plan.weldNumber}</span>.<span> </span>
                                            <span className="title">Material:</span> <span className="title">{plan.m1}</span>/<span className="title">{plan.m2}</span>
                                            {plan.weldMadeDate &&
                                                <span className="subtitle">
                                                    <span>Weld made</span> <span>{DateTime.getLocalDate(plan.weldMadeDate)}</span>
                                                </span>
                                            }
                                        </h5>
                                        <div className="grid-container plan-section">
                                            <div className="grid-child">
                                                <span className="title">3A. WPS Specifics</span>
                                                <ul>
                                                    <li><label>Joint Design</label><span>{plan.jointDesign}</span></li>
                                                    <li>
                                                        <label>WPS No.</label>{!plan.isWpsValid && <span>{plan.wpsNumber}</span>}{plan.isWpsValid && <a href="#" className="link" onClick={e => this.getWpsReport(e, plan.wpsNumber)} title="Click to view WPS"><span>{plan.wpsNumber}</span></a>}
                                                    </li>
                                                    <li><label>Electrode/Rod</label><span>{this.getElectrodes(plan.electrodes, "(None)")}</span></li>
                                                    <li><label>Weld Size (in.)</label><span>{plan.weldSize}</span></li>
                                                    <li><label>Preheat (&deg;F)</label><span>{plan.preheatTemperature}</span></li>
                                                </ul>
                                            </div>
                                            <div className="grid-child">
                                                <span className="title">3B. PWHT Calcs</span>
                                                <ul>
                                                    <li><label>Temp. Range (&deg;F)</label><span>{plan.temperatureRange}</span></li>
                                                    <li><label>Hold Time Min (Hrs)</label><span>{plan.holdTimeMin}</span></li>
                                                    <li><label>Heat Rate Max. (&deg;F/hr)</label><span>{plan.heatRateMax}</span></li>
                                                    <li><label>Cool Rate Max (&deg;F/hr)</label><span>{plan.coolRateMax}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="grid-container plan-section">
                                            <div className="grid-child">
                                                <span className="title">Visual</span>
                                                <ul>
                                                    <li><label>Technician</label><span>{plan.visualTechnician}</span></li>
                                                    <li><label>Exp. Date</label><span>{DateTime.getLocalDate(plan.visualExpiryDate)}</span></li>
                                                </ul>
                                            </div>
                                            <div className="grid-child">
                                                <span className="title">3C. <span>{this.state.selectedDatasheet.ndeMethodTitle}</span></span>
                                                <ul>
                                                    <li><label>Technician</label><span>{plan.ndeTechnician}</span></li>
                                                    <li><label>Exp. Date</label><span>{DateTime.getLocalDate(plan.ndeExpiryDate)}</span></li>
                                                    <li><label>NDE Date</label><span>{DateTime.getLocalDate(plan.weldDate1)}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="grid-container plan-section">
                                            <div className="grid-child">
                                                <span className="title">Defect</span>
                                                <ul>
                                                    <li><label>A/R</label><span>{plan.ar}</span></li>
                                                </ul>
                                            </div>
                                            <div className="grid-child">
                                                <span className="title">Welder</span>
                                                {plan.welders.length === 0 &&
                                                    <ul>
                                                        <li><span>(None)</span></li>
                                                    </ul>
                                                }
                                                {plan.welders.length > 0 &&
                                                    <ul>
                                                        {plan.welders.map((welder: any, index: number) =>
                                                            <li key={index}><a href="#" className="link" onClick={e => this.getWmlReport(e, welder)} title="Click to view WML"><label>{this.getWelder(welder)}</label></a><span></span></li>
                                                        )}
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                        {plan.attachments.length > 0 &&
                                            <div className="header-footer">
                                                <span className="title">Attachments</span>
                                                <div className="grid-container">
                                                    <div className="grid-child">
                                                        <ul>
                                                            {this.getFileList(plan.attachments, 0).map((attachment: { attachmentId: string, fileName: string, fileType: number }, index) =>
                                                                <li key={index} className="attachment">
                                                                    {attachment.fileType === 0 && <FontAwesomeIcon icon={faFile} />}
                                                                    {attachment.fileType === 1 && <FontAwesomeIcon icon={faFilePdf} />}
                                                                    {attachment.fileType === 2 && <FontAwesomeIcon icon={faFileWord} />}
                                                                    {attachment.fileType === 3 && <FontAwesomeIcon icon={faFileExcel} />}
                                                                    {attachment.fileType === 4 && <FontAwesomeIcon icon={faFileImage} />}
                                                                    {attachment.fileType === 5 && <FontAwesomeIcon icon={faFileVideo} />}
                                                                    {attachment.fileType === 6 && <FontAwesomeIcon icon={faFileAudio} />}
                                                                    {attachment.fileType === 7 && <FontAwesomeIcon icon={faFileArchive} />}
                                                                    {attachment.fileType === 8 && <FontAwesomeIcon icon={faFileCode} />}
                                                                    {attachment.fileType === 9 && <FontAwesomeIcon icon={faFileAlt} />}
                                                                    <span> </span>
                                                                    <a href="#" onClick={e => this.getAttachment(e, attachment.attachmentId)} title="Click to View">{attachment.fileName}</a>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                    <div className="grid-child">
                                                        <ul>
                                                            {this.getFileList(plan.attachments, 1).map((attachment: { attachmentId: string, fileName: string, fileType: number }, index) =>
                                                                <li key={index} className="attachment">
                                                                    {attachment.fileType === 0 && <FontAwesomeIcon icon={faFile} />}
                                                                    {attachment.fileType === 1 && <FontAwesomeIcon icon={faFilePdf} />}
                                                                    {attachment.fileType === 2 && <FontAwesomeIcon icon={faFileWord} />}
                                                                    {attachment.fileType === 3 && <FontAwesomeIcon icon={faFileExcel} />}
                                                                    {attachment.fileType === 4 && <FontAwesomeIcon icon={faFileImage} />}
                                                                    {attachment.fileType === 5 && <FontAwesomeIcon icon={faFileVideo} />}
                                                                    {attachment.fileType === 6 && <FontAwesomeIcon icon={faFileAudio} />}
                                                                    {attachment.fileType === 7 && <FontAwesomeIcon icon={faFileArchive} />}
                                                                    {attachment.fileType === 8 && <FontAwesomeIcon icon={faFileCode} />}
                                                                    {attachment.fileType === 9 && <FontAwesomeIcon icon={faFileAlt} />}
                                                                    <span> </span>
                                                                    <a href="#" onClick={e => this.getAttachment(e, attachment.attachmentId)} title="Click to View">{attachment.fileName}</a>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )}
                            </div>

                            <div className="sidebar">
                                <h3 className="sidebar-title">Quality Plan</h3>
                                <table className="table grouped">
                                    <thead>
                                        <tr>
                                            <th>Hold Point</th>
                                            <th></th>
                                            <th>Inspector</th>
                                            <th>Release</th>
                                            <th>Maintenance Release</th>
                                            <th>QCC Audit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.holdPoints.map((holdpoint, index) =>
                                            <React.Fragment key={index}>
                                                {holdpoint.category &&
                                                    <tr>
                                                        <td className="grouping holdpoint" colSpan={6}>
                                                            <span className="aligned">
                                                                {holdpoint.groupNumber &&
                                                                    <span><span>{holdpoint.groupNumber}</span>.</span>
                                                                }
                                                                <span>{holdpoint.category}</span>
                                                            </span>
                                                            {holdpoint.isDirty &&
                                                                <React.Fragment>
                                                                    <button type="button" onClick={e => this.resetHoldPoint(e, holdpoint)} className="btn btn-light cancel">Cancel</button>
                                                                    <button type="button" onClick={e => this.saveHoldPoint(e, holdpoint)} className="btn btn-default save">Save</button>
                                                                </React.Fragment>
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                                <tr className="holdpoint-detail">
                                                    <td>{holdpoint.holdPoint}</td>
                                                    <td>
                                                        <label className="customcheck d-lg-block">
                                                            <input type="checkbox" checked={holdpoint.isRequired} disabled={true} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </td>
                                                    <td>{holdpoint.planner}</td>
                                                    <td>
                                                        {this.allowHoldPointSign(holdpoint) &&
                                                            <a href="#" onClick={e => this.signHoldPoint(e, holdpoint)} className="plain-link">Click to sign</a>
                                                        }
                                                        <span>{holdpoint.release}</span>
                                                    </td>
                                                    <td>{holdpoint.maintenanceRelease}</td>
                                                    <td>{holdpoint.qccAudit}</td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            {this.state.attachments.length > 0 &&
                                <div className="sidebar">
                                    <h3 className="sidebar-title">Attachments</h3>
                                    <div className="grid-container">
                                        <div className="grid-child">
                                            <ul>
                                                {this.getFileList(this.state.attachments, 0).map((attachment: { attachmentId: string, fileName: string, description: string, fileType: number, isSigned: boolean }, index) =>
                                                    <li key={index} className="attachment">
                                                        {attachment.fileType === 0 && <FontAwesomeIcon icon={faFile} />}
                                                        {attachment.fileType === 1 && <FontAwesomeIcon icon={faFilePdf} />}
                                                        {attachment.fileType === 2 && <FontAwesomeIcon icon={faFileWord} />}
                                                        {attachment.fileType === 3 && <FontAwesomeIcon icon={faFileExcel} />}
                                                        {attachment.fileType === 4 && <FontAwesomeIcon icon={faFileImage} />}
                                                        {attachment.fileType === 5 && <FontAwesomeIcon icon={faFileVideo} />}
                                                        {attachment.fileType === 6 && <FontAwesomeIcon icon={faFileAudio} />}
                                                        {attachment.fileType === 7 && <FontAwesomeIcon icon={faFileArchive} />}
                                                        {attachment.fileType === 8 && <FontAwesomeIcon icon={faFileCode} />}
                                                        {attachment.fileType === 9 && <FontAwesomeIcon icon={faFileAlt} />}
                                                        <span> </span>
                                                        <a href="#" onClick={e => this.getAttachment(e, attachment.attachmentId)} title="Click to View">{attachment.fileName}</a>
                                                        {this.allowAttachmentSign(attachment.description) &&
                                                            <p>
                                                                {!attachment.isSigned &&
                                                                    <span><FontAwesomeIcon icon={faSignature} /> <a href="#" onClick={e => this.signAttachment(e, attachment)}>(Sign this document)</a></span>
                                                                }
                                                                {attachment.isSigned &&
                                                                    <span><FontAwesomeIcon icon={faCheck} /> (Signed)</span>
                                                                }
                                                            </p>
                                                        }
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="grid-child">
                                            <ul>
                                                {this.getFileList(this.state.attachments, 1).map((attachment: { attachmentId: string, fileName: string, description: string, fileType: number, isSigned: boolean }, index) =>
                                                    <li key={index} className="attachment">
                                                        {attachment.fileType === 0 && <FontAwesomeIcon icon={faFile} />}
                                                        {attachment.fileType === 1 && <FontAwesomeIcon icon={faFilePdf} />}
                                                        {attachment.fileType === 2 && <FontAwesomeIcon icon={faFileWord} />}
                                                        {attachment.fileType === 3 && <FontAwesomeIcon icon={faFileExcel} />}
                                                        {attachment.fileType === 4 && <FontAwesomeIcon icon={faFileImage} />}
                                                        {attachment.fileType === 5 && <FontAwesomeIcon icon={faFileVideo} />}
                                                        {attachment.fileType === 6 && <FontAwesomeIcon icon={faFileAudio} />}
                                                        {attachment.fileType === 7 && <FontAwesomeIcon icon={faFileArchive} />}
                                                        {attachment.fileType === 8 && <FontAwesomeIcon icon={faFileCode} />}
                                                        {attachment.fileType === 9 && <FontAwesomeIcon icon={faFileAlt} />}
                                                        <span> </span>
                                                        <a href="#" onClick={e => this.getAttachment(e, attachment.attachmentId)} title="Click to View">{attachment.fileName}</a>
                                                        {this.allowAttachmentSign(attachment.description) &&
                                                            <p>
                                                                {!attachment.isSigned &&
                                                                    <span><FontAwesomeIcon icon={faSignature} /> <a href="#" onClick={e => this.signAttachment(e, attachment)}>(Sign this document)</a></span>
                                                                }
                                                                {attachment.isSigned &&
                                                                    <span><FontAwesomeIcon icon={faCheck} /> (Signed)</span>
                                                                }
                                                            </p>
                                                        }
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="sidebar">
                                <h3 className="sidebar-title">Signatures</h3>
                                <div className="grid-container">
                                    <div className="grid-child signature">
                                        <span className="title">QCC Acceptance</span>
                                        <hr />
                                        <span className="name">{this.state.selectedDatasheet.signOff1UserName}</span>
                                        <span>{DateTime.getLocalDate(this.state.selectedDatasheet.signOff1Date)}</span>
                                    </div>
                                    <div className="grid-child signature">
                                        <span className="title">Inspector Acceptance</span>
                                        <hr />
                                        {this.state.allowFinalSignoff &&
                                            <a href="#" onClick={this.promptSignoff} className="plain-link">Click to sign</a>
                                        }
                                        {!this.state.allowFinalSignoff &&
                                            <span>QCC and attachments must be signed.</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}