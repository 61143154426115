import { DatasheetSelection } from "../../common/DatasheetSelection";
import { defaultState } from "./types";
import { MainInterface } from "./components/MainInterface";
import { Navigate, useSearchParams } from "react-router-dom";
import { reducer } from "./reducer";
import { useCheckedOutWelds } from "./queries";
import { useReducer } from "react";
import { WeldSelection } from "./components/WeldSelection";

export function ParalloyWelderRole() {
  const [state, updateState] = useReducer(reducer, defaultState);
  const [searchParams] = useSearchParams();

  const welds = useCheckedOutWelds();

  if (welds.isLoading) {
    return <p>Loading...</p>;
  }

  if (welds.data && welds.data.length > 0) {
    return <MainInterface welds={welds.data} />;
  }

  const currentPage = Number(searchParams.get("page")) || 1;

  if (currentPage === 1) {
    return <DatasheetSelection updateState={updateState} {...state} />;
  } else if (currentPage === 2 && state.subProject) {
    return <WeldSelection state={state} updateState={updateState} />;
  } else {
    return <Navigate to="/rbi" />;
  }
}
