import { CustomVersion } from "@entities";
import { Link, Outlet } from "react-router-dom";
import { useConfiguration } from "@queries/configuration.queries";

type VersionCheckProps = {
  version: CustomVersion;
};

export function VersionCheck({ version }: VersionCheckProps) {
  const { isLoading, data: config } = useConfiguration();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const isCustomVersion = (version: CustomVersion) => {
    switch (version) {
      case "AEP":
        return config?.coordinator?.isAep;
      case "Paralloy":
        return config?.coordinator?.isParalloy;
      case "Hitachi":
        return config?.coordinator?.isHitachi;
      default:
        throw new Error(`Unknown version: ${version}`);
    }
  };

  if (isCustomVersion(version)) {
    return <Outlet />;
  }

  return (
    <p>
      This page requires the {version} version. Click{" "}
      <Link className="link-primary" to="/">
        here
      </Link>{" "}
      to go back.
    </p>
  );
}
