export function ConvertToBase64String(image: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      if (event.target?.result) {
        resolve(event.target.result.toString());
      } else {
        reject(new Error("Failed to convert file to Base64 string"));
      }
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(image);
  });
}
