import { DefaultOptions, QueryClient } from "react-query";

export { QueryClientProvider } from "react-query";

const options: DefaultOptions = {
  queries: {
    retry: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  },
};

export const queryClient = new QueryClient({
  defaultOptions: options,
});
