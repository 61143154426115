import { InspectorContext } from "../reducer";
import { useContext } from "react";

export function WeldSelectionFilters() {
  const { state, updateState } = useContext(InspectorContext);

  function handleFilterChanged(e: React.FormEvent<HTMLInputElement>) {
    updateState({
      type: "Filters",
      payload: { ...state.filters, [e.currentTarget.id]: e.currentTarget.checked },
    });
  }

  return (
    <div className="mt-2">
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="onlyWeldsReadyForNdt"
          checked={state.filters.onlyWeldsReadyForNdt}
          onChange={handleFilterChanged}
        />
        <label className="form-check-label" htmlFor="onlyWeldsReadyForNdt">
          Only welds ready for NDT
        </label>
      </div>

      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="onlyRepairWelds"
          checked={state.filters.onlyRepairWelds}
          onChange={handleFilterChanged}
        />
        <label className="form-check-label" htmlFor="onlyRepairWelds">
          Only repairs
        </label>
      </div>

      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="onlyWeldsFromSequence"
          checked={state.filters.onlyWeldsFromSequence}
          onChange={handleFilterChanged}
        />
        <label className="form-check-label" htmlFor="onlyWeldsFromSequence">
          Only welds from current sequence
        </label>
      </div>
    </div>
  );
}
