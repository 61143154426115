import { createPortal } from "react-dom";
import { ReactElement } from "react";

export type PortalProps = {
  target: string;
  children: ReactElement | ReactElement[];
};

export const Portal = (props: PortalProps) => {
  const element = document.getElementById(props.target);
  if (element) {
    return createPortal(props.children, element);
  }

  return null;
};
